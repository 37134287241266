<template>
  <div class="table-content">
    <ul class="table-wrap table-head">
      <li class="company">企业名称</li>
      <li class="works">作品名称</li>
      <li class="category">类别</li>
    </ul>
    <div @click="handleClick($event)" class="scroll-warp">
      <vue-seamless-scroll :data="listData" :class-option="classOption" class="scroll-content">
        <ul class="table-wrap table-list" v-for="(item, index) in listData" :key="index" :data-company="item.creditNo">
          <li class="company">
            <a :title="item.companyName">{{item.companyName | spliceTheOverWord}}</a>
          </li>
          <li class="works">
            <a :title="item.productionName">{{item.productionName | spliceTheOverWord}}</a>
          </li>
          <li class="category"><a>{{item.typeName}}</a></li>
        </ul>
      </vue-seamless-scroll>
    </div>
    <div class="btn-all" @click="toList(1)">
      <span>查看全部</span>
      <img src="../assets/images/home_list_ico_arrow.png" />
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import Util from "../js/common/Util";
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";
  import baseMixin from "../mixin/baseMixin";

  export default {
    name: "copyrightEnterprise",
    mixins: [baseMixin, listMixin],
    components: {
      vueSeamlessScroll
    },
    data(){
      return{
        classOption: {
          step: 1,
          singleHeight: 54,//滚动高度
          waitTime:1500,
        },
        listData: [],
      }
    },
    watch: {
      $route: { // 亲测有效,我是用来监听query参数变化
        handler() {
          //深度监听，同时也可监听到param参数变化
          this.creditNo = this.$route.query.creditNo

          //初次加载时，样式上，动态选中列表第一条数据
          $('.scroll-warp .table-list').removeClass("active")
          $('.scroll-warp .table-list[data-company='+this.creditNo+']').addClass("active")
        },
        deep: true,
      }
    },
    mounted() {
      let promise =new Promise((resolve,reject) => {
        this.getList(resolve)
      });
      promise.then(() => {
        if (this.listData != null && this.listData.length > 0) {
          let creditNo = null
          if(this.$route.query.creditNo != null) creditNo = this.$route.query.creditNo
          if (creditNo == null || creditNo.length == 0) {
            //初次加载时，样式上，动态选中列表第一条数据
            $('.scroll-warp .table-list').removeClass("active")
            $('.scroll-warp .table-list[data-company='+this.listData[0].creditNo+']').addClass("active")

            //初次加载时，通过路由传值加载企业概览信息
            this.$router.push({
              path: `/`,
              query:{
                creditNo: this.listData[0].creditNo
              }
            })
          }
          else
          {
            $('.scroll-warp .table-list').removeClass("active")
            $('.scroll-warp .table-list[data-company='+creditNo+']').addClass("active")
          }
        }
      })
    },
    methods:{
      ScrollEnd(){
        console.log("ScrollEnd")
      },
      handleClick(event){
        //console.log(event.target)

        //============= 通过操作DOM 控制是否选中 Start ====================//
        //清除active
        $('.scroll-warp .table-list').removeClass("active")

        //重新选中
        let creditNo = ""
        if ($(event.target).is('ul')) {
          creditNo = $(event.target).data("company")
          $('.scroll-warp .table-list[data-company='+creditNo+']').addClass("active")
        }
        else {
          creditNo = $(event.target).parent().parent().data("company")
          $('.scroll-warp .table-list[data-company='+creditNo+']').addClass("active")
        }
        //============= 通过操作DOM 控制是否选中 End ====================//

        //调用父组件中的方法，获取概览信息
        this.$emit('getOverviewInfo',{'creditNo':creditNo})
      },
      getList(thenFun) {
        let form = this.getPaginationForm()
        ServerInterface.post("CopyrightEnterpriseList.html", form, (obj) => {
          this.listData = JSON.parse(obj.enterpriseList)
          if (thenFun) thenFun()
        })
      },
      toList(type){
        this.$router.push({
          path: `/companyList`,
          query:{
            creditNo: this.$route.query.creditNo,
            type: type
          }
        })
      }
    },
    filters: {
      spliceTheOverWord(str) {
        if (str.length > 33) {
          return str.substr(0, 33) + '...'
        } else {
          return str
        }
      }
    },
  }
</script>

<style scoped lang="less">
  .btn-all{
    background-color: #00c5ff;
    position: absolute;
    top: 6px;
    right: 7px;
    color: #fff;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    span{
      margin-right: 5px;
      vertical-align: middle;
    }
  }
</style>
