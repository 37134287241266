<template>
  <div class="cs-scroll">
    <div class="trade-p">
      <div class="btn-back" @click="toList">
        <img src="../assets/images/detail_ico_back.png" />
        <span>返回列表</span>
      </div>
      <div class="small-nav">社保详细信息</div>
    </div>
    <div class="trade-p">
      <table class="detail-table" cellpadding="0" cellspacing="0" border="0">
        <tbody>
          <tr>
            <td class="td-title">年报年份：</td>
            <td>{{socialSecurityObj.report_year}}</td>
            <td class="td-title">发布日期：</td>
            <td>{{socialSecurityObj.report_date}}</td>
            <td class="td-title">企业名称：</td>
            <td>{{socialSecurityObj.name}}</td>
          </tr>
          <tr>
            <td class="td-title">失业保险人数：</td>
            <td>{{socialSecurityObj.social_security.unenployment_num}}</td>
            <td class="td-title">职工基本医疗保险人数：</td>
            <td>{{socialSecurityObj.social_security.insurance_num}}</td>
            <td class="td-title">生育保险人数：</td>
            <td>{{socialSecurityObj.social_security.birth_num}}</td>
          </tr>
          <tr>
            <td class="td-title">城镇职工基本养老保险人数：</td>
            <td>{{socialSecurityObj.social_security.basic_endownment_num}}</td>
            <td class="td-title">工伤保险人数：</td>
            <td colspan="3">{{socialSecurityObj.social_security.injury_insurance_num}}</td>
          </tr>
          <tr>
            <td class="td-title" rowspan="5">单位缴费基数：</td>
            <td colspan="3">单位参加失业保险缴费基数</td>
            <td colspan="2">{{socialSecurityObj.social_security.unenployment_insurance}}</td>
          </tr>
          <tr>
            <td colspan="3">单位参加城镇职工基本养老保险缴费基数</td>
            <td colspan="2">{{socialSecurityObj.social_security.endownment_base_amount}}</td>
          </tr>
          <tr>
            <td colspan="3">单位参加工伤保险缴费基数</td>
            <td colspan="2">{{socialSecurityObj.social_security.injury_insurance_amount}}</td>
          </tr>
          <tr>
            <td colspan="3">单位参加生育保险缴费基数</td>
            <td colspan="2">{{socialSecurityObj.social_security.birth_insurance_count}}</td>
          </tr>
          <tr>
            <td colspan="3">单位参加职工基本医疗保险缴费基数</td>
            <td colspan="2">{{socialSecurityObj.social_security.basic_medical_amount}}</td>
          </tr>
          <tr>
            <td class="td-title" rowspan="5">本期实际缴费金额：</td>
            <td colspan="3">参加失业保险本期实际缴费金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.actual_lost_amount}}</td>
          </tr>
          <tr>
            <td colspan="3">参加职工基本医疗保险本期实际缴费金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.actual_medical_amount}}</td>
          </tr>
          <tr>
            <td colspan="3">参加工伤保险本期实际缴费金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.actual_injury_amount}}</td>
          </tr>
          <tr>
            <td colspan="3">参加生育保险本期实际缴费金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.birth_actual_amount}}</td>
          </tr>
          <tr>
            <td colspan="3">参加城镇职工基本养老保险本期实际缴费金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.actual_endownment_amount}}</td>
          </tr>
          <tr>
            <td class="td-title" rowspan="5">单位累计欠缴金额：</td>
            <td colspan="3">单位参加城镇职工基本养老保险累计欠缴金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.endownment_insurance_amount}}</td>
          </tr>
          <tr>
            <td colspan="3">单位参加职工基本医疗保险累计欠缴金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.base_medical_down_balance}}</td>
          </tr>
          <tr>
            <td colspan="3">单位参加生育保险累计欠缴金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.birth_amount}}</td>
          </tr>
          <tr>
            <td colspan="3">单位参加工伤保险累计欠缴金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.company_injury_down_balance}}</td>
          </tr>
          <tr>
            <td colspan="3">单位参加失业保险累计欠缴金额</td>
            <td colspan="2">{{socialSecurityObj.social_security.unenployment_down_balance}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "socialSecurityDetail",
    mixins: [listMixin],
    data(){
      return{
        pageNumber:'',
        report_year:'',
        socialSecurityObj:{
          name:'',
          report_date:'',
          report_year:'',
          social_security:{}
        }
      }
    },
    mounted() {
      this.report_year = this.$route.query.report_year;
      this.pageNumber = this.$route.query.pageNumber;
      this.getList();
    },
    methods:{
      toList(type){
        this.$router.push({
          path: `/socialSecurityList`,
          query:{
            creditNo: this.$route.query.creditNo,
            pageNumber: this.pageNumber
          }
        })
      },
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        form.pageNumber = this.pageNumber;
        ServerInterface.post("GetSocialSecurityByName.html", form, (obj) => {
          this.refreshPagination(obj);
          let result = JSON.parse(obj.data);
          let socialSecurityList = result.items;
          for (let i = 0; i < socialSecurityList.length; i++) {
            if (this.report_year == socialSecurityList[i].report_year) {
              this.socialSecurityObj = socialSecurityList[i];
            }
          }
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/detail";
</style>
