/**
 * 在该文件中定义常量
 */

//开发环境地址
// const API_SERVER_BASE_URL = "http://127.0.0.1/"

//demo演示环境地址
const API_SERVER_BASE_URL = "https://copyrightedaapi.chisalsoft.co/"

//生产环境地址
// const API_SERVER_BASE_URL = "http://127.0.0.1/"

export default {
	API_SERVER_BASE_URL
}
