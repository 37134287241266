<template>
  <div class="head-bg">
    <!--<img src="../assets/images/home_top_logo.png" class="head-logo"/>-->
    <img src="../assets/images/home_top_title.png" class="head-title">
    <div class="now-time">
      <span>{{nowDate}}</span>
      <span>{{nowWeek}}</span>
      <span>|</span>
      <span>{{nowTime}}</span>
    </div>
    <div class="search">
      <img src="../assets/images/home_top_search.png" class="icon-search">
      <input @keyup.enter="toSearchList" type="text" placeholder="请输入企业名、人名、产品名等关键词" v-model="keyword" />
      <span @click="toSearchList">搜索</span>
    </div>
  </div>
</template>

<script>
  import {Message} from 'element-ui'

  export default {
    name: "headTop",
    data () {
      return {
        timer: null,
        nowWeek: '',
        nowDate: '',
        nowTime: '',
        keyword: '',
      }
    },
    mounted () {
      this.timer = setInterval(() => {
        this.setNowTimes()
      }, 1000)
      let keyword = this.$route.query.keyword;
      if (keyword == 'undefined') {
        this.keyword = '';
      } else {
        this.keyword = keyword;
      }
    },
    methods:{
      setNowTimes () {
        let myDate = new Date()
        let wk = myDate.getDay()
        let yy = String(myDate.getFullYear())
        let mm = myDate.getMonth() + 1
        let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
        let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
        let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
        let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
        let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
        let week = weeks[wk]
        this.nowDate = yy + '-' + mm + '-' + dd
        this.nowTime = hou + ':' + min + ':' + sec
        this.nowWeek = week
      },
      toSearchList() {
        if (this.keyword != null && this.keyword != '') {
          this.$router.push({
            path: `/searchList`,
            query:{
              creditNo: this.$route.query.creditNo,
              keyword: this.keyword
            }
          })
        } else {
          Message.error("请输入关键词")
        }
      }
    }
  }
</script>

<style scoped lang="less">
  ::-webkit-input-placeholder { /* WebKit browsers */
    color: #1a64b3;
  }

  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #1a64b3;
  }

  :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #1a64b3;
  }
  .head-logo{
    margin-left: 25px;
  }
  .head-title{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-189px,-20px);
  }
  .now-time{
    display: inline-block;
    color: #fff;
    vertical-align: middle;
    margin-top: 10px;
    margin-left: 15px;
    span{
      margin: 0 6px;
    }
  }
  .search{
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    .icon-search{
      position: absolute;
      top: 27px;
      left: 10px;
    }
    input{
      background-color: #cce4ff;
      color: #1a64b3;
      border: none;
      padding: 0px 15px 0 32px;
      width: 245px;
      height: 33px;
      line-height: 33px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      vertical-align: middle;
      &:focus{
        outline: none;
      }
    }
    span{
      background-color: #009cff;
      display: inline-block;
      color: #fff;
      padding: 0px 15px;
      height: 33px;
      line-height: 33px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
</style>
