<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">工商股东</div>
        <div class="trade-num">找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <el-table :data="partenerList" border stripe style="width: 100%" height="calc(100vh - 380px)">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="股东名称" min-width="100"></el-table-column>
        <el-table-column prop="stockType" label="股东类型" min-width="130"></el-table-column>
        <el-table-column prop="identifyNo" label="企业证照号" min-width="230"></el-table-column>
        <el-table-column prop="text" label="认缴出资信息" min-width="240">
          <template slot-scope="scope">
            <div v-for="(item,index) in scope.row.shouldCapiItems">
              <div>出资时间：{{item.date}}</div>
              <div>认缴出资额：{{item.capi}}</div>
              <div>出资方式：{{item.type}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="text" label="实缴出资信息" min-width="240">
          <template slot-scope="scope">
            <div v-for="(item,index) in scope.row.realCapiItems">
              <div>出资时间：{{item.date}}</div>
              <div>认缴出资额：{{item.capi}}</div>
              <div>出资方式：{{item.type}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalShouldCapi" label="总认缴" min-width="150"></el-table-column>
        <el-table-column prop="totalRealCapi" label="总实缴" min-width="130"></el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
          background
          @current-change="handleChangePage"
          :current-page="pagination.pageNumber"
          :page-size="pagination.countPerPage"
          layout="prev,pager,next,total,jumper"
          :total="pagination.totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import ServerInterface from "../js/common/ServerInterface";
  import baseMinxin from "../mixin/baseMixin";
  import listMixin from "../mixin/listMixin"

  export default {
    name: "partnersList",
    mixins: [baseMinxin,listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        partenerList: []
      }
    },
    mounted() {
      this.getList();
    },
    methods:{
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        ServerInterface.post("GetPartners.html", form, (obj) => {
          this.refreshPagination(obj);
          let result = JSON.parse(obj.data);
          this.partenerList = result.items;
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
</style>
