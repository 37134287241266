<template>
  <div>
    <div class="cs-scroll">
      <div class="search-p" style="padding-top: 0px">
        <div class="btn-back" @click="toHome">
          <img src="../assets/images/detail_ico_back.png" />
          <span>返回首页</span>
        </div>
        <div class="small-nav">
          找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关结果
        </div>
      </div>
      <div class="search-p">
        <div class="search-list">
          <div class="search-item" v-for="(item,index) in companyList" @click="toOverView(item)">
            <div class="title" ref="companyLetter">{{item.name | firstLetterHandler}}</div>
            <div class="company-info">
              <h1>{{item.name}}</h1>
              <div class="text-item">
                <div>法定代表人：{{item.oper_name}}</div>
                <div>社会统一信用代码：{{item.credit_no}}</div>
              </div>
              <div class="text-item">
                <div>成立日期：{{item.start_date}}</div>
                <div>注册号：{{item.reg_no}}</div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-page">
      <el-pagination
          background
          @current-change="handleChangePage"
          :current-page="pagination.pageNumber"
          :page-size="pagination.countPerPage"
          layout="prev,pager,next,total,jumper"
          :total="pagination.totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import ServerInterface from "../js/common/ServerInterface";
  import baseMinxin from "../mixin/baseMixin";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "search",
    mixins: [baseMinxin,listMixin],
    data(){
      return{
        colorList:['#01bff5','#e4c14b','#0653be'],
        companyList:[]
      }
    },
    watch: {
      $route: { // 亲测有效,我是用来监听query参数变化
        handler() {
          //深度监听，同时也可监听到param参数变化
          this.pagination.keyword = decodeURI(this.$route.query.keyword);
          this.getList();
        },
        deep: true,
      }
    },
    mounted() {
      this.pagination.keyword = decodeURI(this.$route.query.keyword);
      this.getList();
    },
    methods:{
      getList() {
        let form = this.getPaginationForm();
        ServerInterface.post("AdvSearchList.html", form, (obj) => {
          this.refreshPagination(obj);
          let result = JSON.parse(obj.data);
          this.companyList = result.items;
          this.$nextTick(() => {
            let num = 0;
            for( let i = 0;i < this.$refs.companyLetter.length;i++)
            {
              if(num==3)
              {num=0}
              this.$refs.companyLetter[i].style.background=this.colorList[num];
              num++;
            }
          })
        })
      },
      toHome(){
        this.$router.push({
          path: `/`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toOverView(obj) {
        this.$router.push({
          path: `/`,
          query:{
            creditNo: obj.credit_no,
          }
        })
      }
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/list";
</style>
