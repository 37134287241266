<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">知识产权出质</div>
        <div class="trade-num">找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <el-table :data="listData" border stripe style="width: 100%" height="calc(100vh - 345px)">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="注册号" width="180" align="center"></el-table-column>
        <el-table-column prop="type" label="种类" width="100"></el-table-column>
        <el-table-column prop="pledgor" label="出质人名称" min-width="180"></el-table-column>
        <el-table-column prop="pledgor_type" label="出质人类型" width="100"></el-table-column>
        <el-table-column prop="pawnee" label="质权人名称" min-width="180"></el-table-column>
        <el-table-column prop="pawnee_type" label="质权人类型" width="100"></el-table-column>
        <el-table-column prop="status" label="状态" width="110" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status == '有效'" class="btn-td green">{{scope.row.status}}</div>
            <div v-else class="btn-td warning">{{scope.row.status}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="period" label="质权登记期限" min-width="180"></el-table-column>
        <el-table-column prop="public_date" label="公示日期" width="120" align="center"></el-table-column>
      </el-table>
      <!--<div class="cs-page">
        <el-pagination
            @size-change="handleSizeChange"
            background
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNumber"
            :page-size="pagination.countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="pagination.totalCount">
        </el-pagination>
      </div>-->
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import listMixin from "../mixin/listMixin";
  import ServerInterface from "../js/common/ServerInterface";

  export default {
    name: "knowledgeList",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        listData: [],
      }
    },
    mounted() {
      if (this.$route.query.pageNumber !=null && this.$route.query.pageNumber > 0) {
        this.pagination.pageNumber = Number(this.$route.query.pageNumber)
      }
      this.getList()
    },
    methods:{
      handleSizeChange(val) {
        this.pagination.countPerPage = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pagination.pageNumber = val
        this.getList()
      },
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo
        ServerInterface.post("KnowledgeList.html", form, (obj) => {
          this.refreshPagination(obj)
          this.listData = JSON.parse(obj.data).items
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
</style>
