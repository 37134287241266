<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">商标信息</div>
        <div class="trade-num">找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <el-table :data="listData" border stripe style="width: 100%" height="calc(100vh - 380px)">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商标名称" min-width="180"></el-table-column>
        <el-table-column prop="theImg" label="商标" min-width="100" align="center">
          <template slot-scope="scope">
            <el-image style="width: 60px; height: 60px;" :src="scope.row.image_name" fit="contain">
              <div slot="error" style="text-align: center;font-size: 20px;line-height: 60px;background-color: #f5f7fa;">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="reg_number" label="注册号" width="180"></el-table-column>
        <el-table-column prop="type_name" label="商标分类" min-width="180" align="center"></el-table-column>
        <el-table-column prop="apply_date" label="申请日期" min-width="110" align="center"></el-table-column>
        <el-table-column prop="period" label="专用权期限" min-width="110" align="center"></el-table-column>
        <el-table-column prop="status" label="法律状态" min-width="110" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status == '注册'" class="btn-td green">{{scope.row.status}}</div>
            <div v-else-if="scope.row.status == '申请被驳回、不予受理等，该商标已失效'" class="btn-td red">{{scope.row.status}}</div>
            <div v-else class="btn-td warning">{{scope.row.status}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="110" align="center">
          <template slot-scope="scope">
            <div @click="toTradeDetail(scope.row)" style="cursor:pointer;">查看详情</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            @size-change="handleSizeChange"
            background
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNumber"
            :page-size="pagination.countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="pagination.totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "tradeMarkList",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        listData: [],
      }
    },
    mounted() {
      if (this.$route.query.pageNumber !=null && this.$route.query.pageNumber > 0) {
        this.pagination.pageNumber = Number(this.$route.query.pageNumber)
      }
      this.getList();
    },
    methods:{
      handleSizeChange(val) {
        this.pagination.countPerPage = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pagination.pageNumber = val
        this.getList()
      },
      toTradeDetail(obj){
        this.$router.push({
          path: `/tradeMarkDetail`,
          query:{
            creditNo: this.$route.query.creditNo,
            tradeMarkRegNumber: obj.reg_number,
            pageNumber: this.pagination.pageNumber,
          }
        })
      },
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo
        ServerInterface.post("TrademarkList.html", form, (obj) => {
          this.refreshPagination(obj)
          this.listData = JSON.parse(obj.data).items
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
</style>
