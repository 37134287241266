<template>
  <div v-wechat-title="$route.meta.title" class="container">
    <head-top></head-top>
    <div class="table-left">
      <div class="copyright">
        <h1 class="title-h1">作品著作权企业</h1>
        <copyright-enterprise @getOverviewInfo="getOverviewInfo"></copyright-enterprise>
      </div>
      <div class="copyright-soft">
        <h1 class="title-h1">软件著作权企业</h1>
        <copyright-soft-enterprise @getOverviewInfo="getOverviewInfo"></copyright-soft-enterprise>
      </div>
    </div>
    <div class="detail-right">
      <router-view ref="routerView"></router-view>
    </div>
  </div>
</template>

<script>
  import copyrightEnterprise from '@/components/copyrightEnterprise.vue'
  import copyrightSoftEnterprise from '@/components/copyrightSoftEnterprise.vue'
  import headTop from '@/components/headTop.vue'
  export default {
    name: 'App',
    components: {
      copyrightEnterprise,
      copyrightSoftEnterprise,
      headTop,
    },
    methods: {
      getOverviewInfo(obj) {
        //路由传值
        this.$router.push({
          path: `/`,
          query:{
            creditNo: obj.creditNo
          }
        })
      }
    }
  }
</script>

<style lang="less">

</style>
