<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">对外投资</div>
        <div class="trade-num">找到 <span>{{investmentList.length | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <el-table :data="investmentList" border stripe style="width: 100%" height="calc(100vh - 345px)">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="enterprise" label="企业名称" min-width="200"></el-table-column>
        <el-table-column prop="short_name" label="企业简称" min-width="160"></el-table-column>
        <el-table-column prop="amount" label="投资金额(万元)" min-width="130"></el-table-column>
        <el-table-column prop="percent" label="占股比" min-width="140" align="center"></el-table-column>
        <el-table-column prop="sh_type" label="股东类型" min-width="160" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin"

  export default {
    name: "investmentList",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        investmentList: []
      }
    },
    mounted() {
      this.getList();
    },
    methods:{
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        ServerInterface.post("GetInvestmentByName.html", form, (obj) => {
          let result = JSON.parse(obj.data)
          this.investmentList = result.items;
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
</style>
