<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">专利信息</div>
        <div class="trade-num">找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <el-table :data="listData" border stripe style="width: 100%" height="calc(100vh - 380px)">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="type_name" label="专利类型" width="180" align="center"></el-table-column>
        <el-table-column prop="outhor_num" label="申请公布号" width="180"></el-table-column>
        <el-table-column prop="patent_name" label="专利名称" min-width="180"></el-table-column>
        <el-table-column prop="request_date" label="申请日期" width="120" align="center"></el-table-column>
        <el-table-column prop="outhor_date" label="授权公布日" width="120" align="center"></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <div @click="toDetail(scope.row)" style="cursor:pointer;">查看详情</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            @size-change="handleSizeChange"
            background
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNumber"
            :page-size="pagination.countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="pagination.totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import listMixin from "../mixin/listMixin";
  import ServerInterface from "../js/common/ServerInterface";

  export default {
    name: "patentList",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        listData: [],
      }
    },
    mounted() {
      if (this.$route.query.pageNumber !=null && this.$route.query.pageNumber > 0) {
        this.pagination.pageNumber = Number(this.$route.query.pageNumber)
      }
      this.getList()
    },
    methods:{
      handleSizeChange(val) {
        this.pagination.countPerPage = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pagination.pageNumber = val
        this.getList()
      },
      toDetail(obj){
        this.$router.push({
          path: `/patentDetail`,
          query:{
            creditNo: this.$route.query.creditNo,
            patentRequestNum: obj.request_num,
            pageNumber: this.pagination.pageNumber,
          }
        })
      },
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo
        ServerInterface.post("PatentList.html", form, (obj) => {
          this.refreshPagination(obj)
          this.listData = JSON.parse(obj.data).items
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
</style>
