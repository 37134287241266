<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">企业简介</div>
        <div class="trade-num">共 <span>1</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <table class="detail-table" cellpadding="0" cellspacing="0" border="0">
        <tbody>
          <tr>
            <td class="td-title">企业名称：</td>
            <td colspan="5">{{entBriefObj.name}}</td>
          </tr>
          <tr>
            <td class="td-title">企业简介：</td>
            <td colspan="5">{{entBriefObj.brief}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "entBriefByName",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        entBriefObj:{}
      }
    },
    mounted() {
      this.getEntBriefByName();
    },
    methods:{
      getEntBriefByName() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        ServerInterface.post("GetEntBriefByName.html", form, (obj) => {
          this.entBriefObj = JSON.parse(obj.data);
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
  @import "src/assets/css/detail";
</style>
