<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">社保信息</div>
        <div class="trade-num">找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <el-table :data="socialSecurityList" border stripe style="width: 100%" height="calc(100vh - 380px)">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="report_year" label="年份" width="130" align="center"></el-table-column>
        <el-table-column prop="report_date" label="发布日期" width="130" align="center"></el-table-column>
        <el-table-column prop="social_security.unenployment_num" label="失业保险人数" min-width="130" align="center"></el-table-column>
        <el-table-column prop="social_security.insurance_num" label="职工基本医疗保险人数" min-width="180" align="center"></el-table-column>
        <el-table-column prop="social_security.birth_num" label="生育保险人数" min-width="130" align="center"></el-table-column>
        <el-table-column prop="social_security.basic_endownment_num" label="城镇职工基本养老保险人数" min-width="200" align="center"></el-table-column>
        <el-table-column prop="social_security.injury_insurance_num" label="工伤保险人数" min-width="130" align="center"></el-table-column>
        <el-table-column label="操作" width="110" align="center">
          <template slot-scope="scope">
            <div @click="toDetail(scope.row.report_year)" style="cursor:pointer;">查看详情</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
          background
          @current-change="handleChangePage"
          :current-page="pagination.pageNumber"
          :page-size="pagination.countPerPage"
          layout="prev,pager,next,total,jumper"
          :total="pagination.totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import ServerInterface from "../js/common/ServerInterface";
  import baseMinxin from "../mixin/baseMixin";
  import listMixin from "../mixin/listMixin"

  export default {
    name: "socialSecurityList",
    mixins: [baseMinxin,listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        socialSecurityList: []
      }
    },
    mounted() {
      if (this.$route.query.pageNumber != null && this.$route.query.pageNumber != 'undefined' && this.$route.query.pageNumber != '') {
        this.pagination.pageNumber = Number(this.$route.query.pageNumber)
      }
      this.getList();
    },
    methods:{
      toDetail(report_year){
        this.$router.push({
          path: `/socialSecurityDetail`,
          query:{
            creditNo: this.$route.query.creditNo,
            report_year: report_year,
            pageNumber: this.pagination.pageNumber
          }
        })
      },
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        ServerInterface.post("GetSocialSecurityByName.html", form, (obj) => {
          this.refreshPagination(obj);
          let result = JSON.parse(obj.data);
          this.socialSecurityList = result.items;
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
</style>
