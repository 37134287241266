// 引用API文件
import GlobalData from './GlobalData.js';

// 配置API接口地址
var serverUrl = GlobalData.API_SERVER_BASE_URL;
// 引用axios
import axios from 'axios'
import {Message, Loading} from 'element-ui'
import Cookies from 'js-cookie'
import Util from "./Util";

// 自定义判断元素类型JS
function toType(obj) {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}

// 参数过滤函数 就是用来清空对象、数组中的空元素和清空字符串元素中的空格
function filterNull(o) {
  for (var key in o) {
    if (o[key] === null) {//删除空元素
      delete o[key]
    }
    if (toType(o[key]) === 'string') {//删除字符串元素中的空格
      o[key] = o[key].trim()
    } else if (toType(o[key]) === 'object') {
      o[key] = filterNull(o[key])
    } else if (toType(o[key]) === 'array') {
      o[key] = filterNull(o[key])
    }
  }
  return o
}

/*
  接口处理函数
  这个函数每个项目都是不一样的，我现在调整的是适用于
  https://cnodejs.org/api/v1 的接口，如果是其他接口
  需要根据接口的参数进行调整。参考说明文档地址：
  https://cnodejs.org/topic/5378720ed6e2d16149fa16bd
  主要是，不同的接口的成功标识和失败提示是不一致的。
  另外，不同的项目的处理方法也是不一致的，这里出错就是简单的alert
*/
function apiAxios(method, url, params, onSuccess, onFailure, onCatch) {
  //memberId = 3
  // localStorage.setItem("memberToken","j1zhishuQDbJzDoUq7p1BB36QUAWu1uRaLnl0ukYecc%3D")
  // localStorage.setItem("adminToken","itablwXlACBc0YfiQrANqfSXuH6BCdXDJ7zB6Hu9ZaI%3D")
  // if (params) {
  //   params['loginAdminToken'] = localStorage.getItem("loginAdminToken")
  //   params['selectLanguage'] = localStorage.getItem("selectLanguage")
  //   params = filterNull(params)
  // }

  //解决：由于用户未在浏览器登录直接访问数据，有跨域问题
  // if ((localStorage.getItem("loginAdminToken") == null || localStorage.getItem("loginAdminToken").length == 0) && url != null && url.endsWith(".sahtml")) {
  //   window.location.href = "../../../../login.html"
  // }

  // var admin = Util.getCookieObj('admin')
  // if(admin === undefined)
  // {
  //   admin = JSON.parse(sessionStorage.getItem("admin"));
  // }
  let admin=Util.getCookieObj("loginAdmin")
  if ((admin === undefined || admin == null)&& url.endsWith(".sahtml")) {
    Message.error('请您先登录')
     window.location.href = "../"
  }

  axios({
    url: url,
    baseURL: serverUrl,
    method: method,
    data: method === 'POST' || method === 'PUT' ? params : null,
    params: method === 'GET' || method === 'DELETE' ? params : null,
    withCredentials: false
  })
    .then(function (response) {
      let data = response.data;
      if (data.result === "success") {
        if (onSuccess) {
          onSuccess(data)
        }
      } else {
        if (onFailure) {
          // let resultCode = data.resultCode;
          //
          // if (resultCode == 2000) {
          //   //失效后需要清空，本地存储的用户登录信息
          //   localStorage.removeItem("adminToken")
          //   //退出登录跳转登录页 TODO
          //   location.href = "login.html#/"
          //   return;
          // }

          onFailure(data)
        } else {
          // window.alert('error: ' + JSON.stringify(data))
          Message.error(data.info)
        }
      }
    })
    .catch(function (err) {
      if (onCatch) {
        onCatch(err)
      } else {
        // Message.error('api error, HTTP CODE: ' + err)
        console.log(err)
      }
    })
}

function defaultPost(interfaceName, params, onSuccess, onFail, showSuccess = false, showFail = true, showLoading = true, loadingWord) {
  // console.log('defaultPost params is ')
  // console.log(params)
  var loadingInstance = undefined
  var tempLoadingWord = undefined
  if (loadingWord === undefined) {
    tempLoadingWord = "Loading..."
  } else {
    tempLoadingWord = loadingWord
  }
  if (showLoading) {
    loadingInstance = Loading.service({
      lock: true,
      text: tempLoadingWord,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.6)'
    });
  }
  apiAxios('POST', interfaceName, params, (data) => {
    if (showLoading) {
      loadingInstance.close()
    }
    if (showSuccess) {
      Message.success(data.info)
    }
    onSuccess(data)
  }, (data) => {
    console.log(data)
    if (showFail) {
      if (data.info === undefined) {
        Message.error('Operate fail')
      } else {
        Message.error(data.info)
      }
    }
    if (showLoading) {
      loadingInstance.close()
    }
    if (onFail) {
      onFail()
    }
  }, (err) => {
    console.log(err)
    if (showLoading) {
      loadingInstance.close()
    }
    Message.error("提交参数错误或服务器内部错误")
  })
}

// 返回在vue模板中的调用接口
export default {
  get(url, params, onSuccess, onFailure, onCatch) {
    return apiAxios('GET', url, params, onSuccess, onFailure, onCatch)
  },
  post(url, params, onSuccess, onFailure, onCatch) {
    return apiAxios('POST', url, params, onSuccess, onFailure, onCatch)
  },
  put(url, params, onSuccess, onFailure, onCatch) {
    return apiAxios('PUT', url, params, onSuccess, onFailure, onCatch)
  },
  delete(url, params, onSuccess, onFailure, onCatch) {
    return apiAxios('DELETE', url, params, onSuccess, onFailure, onCatch)
  },
  defaultPost,
}
