<template>
  <div class="cs-scroll">
    <div class="trade-p">
      <div class="btn-back" @click="toList">
        <img src="../assets/images/detail_ico_back.png" />
        <span>返回列表</span>
      </div>
      <div class="small-nav">商标详情</div>
    </div>
    <div class="trade-p">
      <div class="trade-bg">
        <div class="trade-img">
          <el-image style="width: 96px; height: 96px;" :src="detailData.image_url" fit="contain" v-if="detailData.image_url">
            <div slot="error" style="text-align: center;font-size: 20px;line-height: 96px;background-color: #f5f7fa;">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="trade-info">
          <h1>{{detailData.name}}</h1>
          <span class="btn-td green" v-if="detailData.status === '注册'">{{detailData.status}}</span>
          <span class="btn-td red" v-else-if="detailData.status === '申请被驳回、不予受理等，该商标已失效'">{{detailData.status}}</span>
          <span class="btn-td warning" v-else>{{detailData.status}}</span>
          <div class="other-info">
            <span>注册号：{{detailData.reg_number}}</span>
            <span>申请日期：{{detailData.apply_date}}</span>
            <span>企业名称：{{detailData.ename}}</span>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="trade-p">
      <table class="detail-table" cellpadding="0" cellspacing="0" border="0">
        <tbody>
          <tr>
            <td class="td-title">商标名称：</td>
            <td>{{detailData.name}}</td>
            <td class="td-title">状态：</td>
            <td>{{detailData.status}}</td>
            <td class="td-title">申请日期：</td>
            <td>{{detailData.apply_date}}</td>
          </tr>
          <tr>
            <td class="td-title">注册号：</td>
            <td>{{detailData.reg_number}}</td>
            <td class="td-title">商标分类：</td>
            <td>{{detailData.type_num}}</td>
            <td class="td-title">开始日期（专用权期限）：</td>
            <td>{{detailData.start_date}}</td>
          </tr>
          <tr>
            <td class="td-title">申请人名称(中文)：</td>
            <td>{{detailData.company}}</td>
            <td class="td-title">申请人名称(英文)：</td>
            <td>{{detailData.company_en}}</td>
            <td class="td-title">专用权截止日期：</td>
            <td>{{detailData.end_date}}</td>
          </tr>
          <tr>
            <td class="td-title">申请人地址(中文)：</td>
            <td>{{detailData.address_cn}}</td>
            <td class="td-title">申请人地址(英文)：</td>
            <td>{{detailData.address_en}}</td>
            <td class="td-title">国际注册日期：</td>
            <td>{{detailData.global_date}}</td>
          </tr>
          <tr>
            <td class="td-title">初审公告期号：</td>
            <td>{{detailData.first_pubno}}</td>
            <td class="td-title">注册公告期号：</td>
            <td>{{detailData.reg_pubno}}</td>
            <td class="td-title">优先权日期：</td>
            <td>{{detailData.priority_date}}</td>
          </tr>
          <tr>
            <td class="td-title">初审公告日期：</td>
            <td>{{detailData.first_pubdate}}</td>
            <td class="td-title">注册公告日期：</td>
            <td>{{detailData.reg_pubdate}}</td>
            <td class="td-title">后期指定日期：</td>
            <td>{{detailData.last_special_date}}</td>
          </tr>
          <tr>
            <td class="td-title">是否拥有商标：</td>
            <td>{{detailData.is_shared}}</td>
            <td class="td-title">商标类型：</td>
            <td>{{detailData.trademarktype}}</td>
            <td class="td-title">代理人名称：</td>
            <td>{{detailData.agent}}</td>
          </tr>
          <tr>
            <td class="td-title">商标公告：</td>
            <td colspan="5">
              <div v-if="detailData.notices == null || detailData.notices.length == 0">-</div>
              <div v-if="detailData.notices != null && detailData.notices.length > 0" v-for="noticeObj in detailData.notices">{{noticeObj.content}}</div>
            </td>
          </tr>
          <tr>
            <td class="td-title">商标流程：</td>
            <td colspan="5">
              <div v-for="stepObj in detailData.steps">{{stepObj.date}}{{stepObj.step}}</div>
            </td>
          </tr>
          <tr>
            <td class="td-title">产品服务：</td>
            <td colspan="5">
              <div v-for="productObj in detailData.products">{{productObj.name}}（{{productObj.num}}）</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import listMixin from "../mixin/listMixin";
  import ServerInterface from "../js/common/ServerInterface";

  export default {
    name: "tradeMarkDetail",
    mixins: [listMixin],
    data(){
      return{
        detailData:{},
      }
    },
    mounted() {
      this.getDetail()
    },
    methods:{
      toList(){
        this.$router.push({
          path: `/tradeMarkList`,
          query:{
            creditNo: this.$route.query.creditNo,
            pageNumber: this.$route.query.pageNumber,
          }
        })
      },
      getDetail() {
        let form = this.getBaseForm()
        form.creditNo = this.$route.query.creditNo
        form.registrationNumber = this.$route.query.tradeMarkRegNumber
        ServerInterface.post("TrademarkDetail.html", form, (obj) => {
          this.detailData = JSON.parse(obj.data)
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/detail";
</style>