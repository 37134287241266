<template>
  <div class="cs-scroll">
    <div class="trade-p">
      <div class="btn-back" @click="toList">
        <img src="../assets/images/detail_ico_back.png" />
        <span>返回列表</span>
      </div>
      <div class="small-nav">动产抵押详细信息</div>
    </div>
    <div class="trade-p">
      <table class="detail-table" cellpadding="0" cellspacing="0" border="0">
        <tbody>
          <tr>
            <td class="td-title">登记编号：</td>
            <td>{{mortgageObj.num}}</td>
            <td class="td-title">状态：</td>
            <td>{{mortgageObj.status}}</td>
            <td class="td-title">登记日期：</td>
            <td>{{mortgageObj.date}}</td>
          </tr>
          <tr>
            <td class="td-title">登记机关：</td>
            <td>{{mortgageObj.department}}</td>
            <td class="td-title">被担保债权数额：</td>
            <td>{{mortgageObj.amount}}</td>
            <td class="td-title">被担保债权种类：</td>
            <td>{{mortgageObj.type}}</td>
          </tr>
          <tr>
            <td class="td-title">债务人履行债务的期限：</td>
            <td>{{mortgageObj.period}}</td>
            <td class="td-title">注销日期：</td>
            <td>{{mortgageObj.close_date}}</td>
            <td class="td-title">注销原因：</td>
            <td>{{mortgageObj.close_reason}}</td>
          </tr>
          <tr>
            <td class="td-title">担保范围：</td>
            <td colspan="5">{{mortgageObj.scope}}</td>
          </tr>
          <tr>
            <td class="td-title">备注：</td>
            <td colspan="5">{{mortgageObj.remarks}}</td>
          </tr>
          <tr>
            <td class="td-title" rowspan="5">被担保债权概况</td>
            <td colspan="2">种类</td>
            <td colspan="3">{{mortgageObj.debit_type}}</td>
          </tr>
          <tr>
            <td colspan="2">数额</td>
            <td colspan="3">{{mortgageObj.debit_amount}}</td>
          </tr>
          <tr>
            <td colspan="2">币种</td>
            <td colspan="3">{{mortgageObj.debit_currency}}</td>
          </tr>
          <tr>
            <td colspan="2">担保的范围</td>
            <td colspan="3">{{mortgageObj.debit_scope}}</td>
          </tr>
          <tr>
            <td colspan="2">债务人履行债务的期限</td>
            <td colspan="3">{{mortgageObj.debit_period}}</td>
          </tr>
          <tr>
            <td colspan="2">备注</td>
            <td colspan="3">{{mortgageObj.debit_remarks}}</td>
          </tr>
          <tr>
            <td class="td-title">抵押权人：</td>
            <td colspan="5">
              <div>
                <div>序号：{{mortgageObj.mortgagees.seq_no}}</div>
                <div>名称：{{mortgageObj.mortgagees.name}}</div>
                <div>类型：{{mortgageObj.mortgagees.identify_type}}</div>
                <div>抵押权企业证照号：{{mortgageObj.mortgagees.identify_no}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="td-title">抵押物：</td>
            <td colspan="5">
              <div>
                <div>序号：{{mortgageObj.guarantees.seq_no}}</div>
                <div>名称：{{mortgageObj.guarantees.name}}</div>
                <div>所有权归属：{{mortgageObj.guarantees.belong_to}}</div>
                <div>描述：{{mortgageObj.guarantees.desc}}</div>
                <div>备注：{{mortgageObj.guarantees.remarks}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="td-title">是否是历史公示：</td>
            <td colspan="5" v-if="mortgageObj.disabled == '0'">当前公示信息</td>
            <td colspan="5" v-if="mortgageObj.disabled == '1'">历史公示信息</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "mortgagesDetail",
    mixins: [listMixin],
    data(){
      return{
        pageNumber:'',
        num:'',
        date:'',
        mortgageesName:'',
        mortgageObj:{
          mortgagees:{},
          guarantees:{},
        },
      }
    },
    mounted() {
      this.num = this.$route.query.num;
      this.date = this.$route.query.date;
      this.mortgageesName = this.$route.query.mortgageesName;
      this.pageNumber = this.$route.query.pageNumber;
      this.getList();
    },
    methods:{
      toList(){
        this.$router.push({
          path: `/mortgagesList`,
          query:{
            creditNo: this.$route.query.creditNo,
            pageNumber: this.pageNumber
          }
        })
      },
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        form.pageNumber = this.pageNumber;
        ServerInterface.post("GetMortgagesByName.html", form, (obj) => {
          this.refreshPagination(obj);
          let result = JSON.parse(obj.data);
          let mortgageList = result.items;
          for (let i = 0; i < mortgageList.length; i++) {
            if (this.num == mortgageList[i].num && this.date == mortgageList[i].date && this.mortgageesName == mortgageList[i].mortgagees.name) {
              this.mortgageObj = mortgageList[i];
            }
          }
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/detail";
</style>
