<template>
  <div class="cs-scroll">
    <div class="trade-p">
      <div class="btn-back" @click="toList">
        <img src="../assets/images/detail_ico_back.png" />
        <span>返回列表</span>
      </div>
      <div class="small-nav">股权出质详细信息</div>
    </div>
    <div class="trade-p">
      <table class="detail-table" cellpadding="0" cellspacing="0" border="0">
        <tbody>
          <tr>
            <td class="td-title">登记编号：</td>
            <td>{{qualityObj.number}}</td>
            <td class="td-title">状态：</td>
            <td>{{qualityObj.status}}</td>
            <td class="td-title">股权出质登记日期：</td>
            <td>{{qualityObj.date}}</td>
          </tr>
          <tr>
            <td class="td-title">出质人：</td>
            <td>{{qualityObj.pledgor}}</td>
            <td class="td-title">出质人类型：</td>
            <td>{{qualityObj.pledgor_identify_type}}</td>
            <td class="td-title">出质企业证照号：</td>
            <td>{{qualityObj.pledgor_identify_no}}</td>
          </tr>
          <tr>
            <td class="td-title">出质股权数：</td>
            <td>{{qualityObj.pledgor_amount}}</td>
            <td class="td-title">出质股权数额单位：</td>
            <td>{{qualityObj.pledgor_unit}}</td>
            <td class="td-title">出质股权数额币种：</td>
            <td>{{qualityObj.pledgor_currency}}</td>
          </tr>
          <tr>
            <td class="td-title">质权人：</td>
            <td>{{qualityObj.pawnee}}</td>
            <td class="td-title">质权人类型：</td>
            <td>{{qualityObj.pawnee_identify_type}}</td>
            <td class="td-title">质权企业证照号：</td>
            <td>{{qualityObj.pawnee_identify_no}}</td>
          </tr>
          <tr>
            <td class="td-title">变更记录序号：</td>
            <td>{{qualityObj.change_items.seq_no}}</td>
            <td class="td-title">变更日期：</td>
            <td>{{qualityObj.change_items.change_date}}</td>
            <td class="td-title">标的方：</td>
            <td>{{qualityObj.object_company}}</td>
          </tr>
          <tr>
            <td class="td-title">变更内容：</td>
            <td colspan="5">{{qualityObj.change_items.change_content}}</td>
          </tr>
          <tr>
            <td class="td-title">是否是历史公示：</td>
            <td colspan="5" v-if="qualityObj.disabled == '0'">当前公示信息</td>
            <td colspan="5" v-if="qualityObj.disabled == '1'">历史公示信息</td>
          </tr>
          <tr>
            <td class="td-title">备注：</td>
            <td colspan="5">{{qualityObj.remark}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "qualitiesDetail",
    mixins: [listMixin],
    data(){
      return{
        pageNumber:'',
        date:'',
        number:'',
        pledgor:'',
        qualityObj:{
          change_items:{}
        },
      }
    },
    mounted() {
      this.date = this.$route.query.date;
      this.number = this.$route.query.number;
      this.pledgor = this.$route.query.pledgor;
      this.pageNumber = this.$route.query.pageNumber;
      this.getList();
    },
    methods:{
      toList(){
        this.$router.push({
          path: `/qualitiesList`,
          query:{
            creditNo: this.$route.query.creditNo,
            pageNumber: this.pageNumber
          }
        })
      },
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        form.pageNumber = this.pageNumber;
        ServerInterface.post("GetEquityQualitiesByName.html", form, (obj) => {
          this.refreshPagination(obj);
          let result = JSON.parse(obj.data);
          let qualityList = result.items;
          for (let i = 0; i < qualityList.length; i++) {
            if (this.date == qualityList[i].date && this.number == qualityList[i].number && this.pledgor == qualityList[i].pledgor) {
              this.qualityObj = qualityList[i];
            }
          }
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/detail";
</style>
