import { render, staticRenderFns } from "./socialSecurityList.vue?vue&type=template&id=6d295dca&scoped=true&"
import script from "./socialSecurityList.vue?vue&type=script&lang=js&"
export * from "./socialSecurityList.vue?vue&type=script&lang=js&"
import style0 from "./socialSecurityList.vue?vue&type=style&index=0&id=6d295dca&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d295dca",
  null
  
)

export default component.exports