import Vue from 'vue'
import VueRouter from 'vue-router'

//引入页面
import overview from "./components/overview";
import searchList from "./components/searchList";
import companyList from "./components/companyList";
import tradeMarkList from "./components/tradeMarkList";
import tradeMarkDetail from "./components/tradeMarkDetail";
import patentList from "./components/patentList";
import patentDetail from "./components/patentDetail";
import copyrightList from "./components/copyrightList";
import qualificationList from "./components/qualificationList";
import basicInfo from "./components/basicInfo";
import partnersList from "./components/partnersList";
import employeesList from "./components/employeesList";
import contactInfo from "./components/contactInfo";
import websitesList from "./components/websitesList";
import entBriefByName from "./components/entBriefByName";
import socialSecurityList from "./components/socialSecurityList";
import socialSecurityDetail from "./components/socialSecurityDetail";
import investmentList from "./components/investmentList";
import qualitiesList from "./components/qualitiesList";
import qualitiesDetail from "./components/qualitiesDetail";
import mortgagesList from "./components/mortgagesList";
import mortgagesDetail from "./components/mortgagesDetail";
import knowledgeList from "./components/knowledgeList";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

export default new VueRouter({
  routes: [{
    path: '/',
    name: 'overview',
    component: overview,
    meta: {
      title: ['概览'],
    }
  },{
    path: '/searchList',
    name: 'searchList',
    component: searchList,
    meta: {
      title: ['搜索列表'],
    }
  },{
    path: '/companyList',
    name: 'companyList',
    component: companyList,
    meta: {
      title: ['企业列表'],
    }
  },{
    path: '/tradeMarkList',
    name: 'tradeMarkList',
    component: tradeMarkList,
    meta: {
      title: ['商标列表'],
    }
  },{
    path: '/tradeMarkDetail',
    name: 'tradeMarkDetail',
    component: tradeMarkDetail,
    meta: {
      title: ['商标详情'],
    }
  },{
    path: '/patentList',
    name: 'patentList',
    component: patentList,
    meta: {
      title: ['专利列表'],
    }
  },{
    path: '/patentDetail',
    name: 'patentDetail',
    component: patentDetail,
    meta: {
      title: ['专利详情'],
    }
  },{
    path: '/copyrightList',
    name: 'copyrightList',
    component: copyrightList,
    meta: {
      title: ['著作权列表'],
    }
  },{
    path: '/qualificationList',
    name: 'qualificationList',
    component: qualificationList,
    meta: {
      title: ['资质认证'],
    }
  },{
    path: '/basicInfo',
    name: 'basicInfo',
    component: basicInfo,
    meta: {
      title: ['工商照面'],
    }
  },{
    path: '/partnersList',
    name: 'partnersList',
    component: partnersList,
    meta: {
      title: ['工商股东'],
    }
  },{
    path: '/employeesList',
    name: 'employeesList',
    component: employeesList,
    meta: {
      title: ['主要人员'],
    }
  },{
    path: '/contactInfo',
    name: 'contactInfo',
    component: contactInfo,
    meta: {
      title: ['企业联系方式'],
    }
  },{
    path: '/websitesList',
    name: 'websitesList',
    component: websitesList,
    meta: {
      title: ['企业年报网址'],
    }
  },{
    path: '/entBriefByName',
    name: 'entBriefByName',
    component: entBriefByName,
    meta: {
      title: ['企业简介'],
    }
  },{
    path: '/socialSecurityList',
    name: 'socialSecurityList',
    component: socialSecurityList,
    meta: {
      title: ['社保信息'],
    }
  },{
    path: '/socialSecurityDetail',
    name: 'socialSecurityDetail',
    component: socialSecurityDetail,
    meta: {
      title: ['社保信息详情'],
    }
  },{
    path: '/investmentList',
    name: 'investmentList',
    component: investmentList,
    meta: {
      title: ['对外投资'],
    }
  },{
    path: '/qualitiesList',
    name: 'qualitiesList',
    component: qualitiesList,
    meta: {
      title: ['股权出质'],
    }
  },{
    path: '/qualitiesDetail',
    name: 'qualitiesDetail',
    component: qualitiesDetail,
    meta: {
      title: ['股权出质详情'],
    }
  },{
    path: '/mortgagesList',
    name: 'mortgagesList',
    component: mortgagesList,
    meta: {
      title: ['动产抵押'],
    }
  },{
    path: '/mortgagesDetail',
    name: 'mortgagesDetail',
    component: mortgagesDetail,
    meta: {
      title: ['动产抵押详情'],
    }
  },{
    path: '/knowledgeList',
    name: 'knowledgeList',
    component: knowledgeList,
    meta: {
      title: ['知识产权出质'],
    }
  }]
})
