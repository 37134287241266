import ServerInterface from "../js/common/ServerInterface"
import Util from "../js/common/Util";

let context

export default {
  data() {
    return {
      util:Util,
    }
  },
  watch: {},
  created(){
    context = this
    // console.log('baseMixin context is ', context)
  },
  mounted() {

  },
  methods: {},
}
