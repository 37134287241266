<template>
  <div class="cs-scroll">
    <head-company></head-company>
    <div class="cs-item">
      <div class="bg" style="min-height: 349px">
        <div class="title">作品著作权概览</div>
        <div class="cr-list" v-for="(item,index) in copyrightList" v-if="copyrightList != null && copyrightList.length > 0">
          <div class="text-margin">
            <a class="text-title" :title="item.name">{{item.name}}</a>
            <span class="text-label">{{item.type_name}}</span>
            <div class="clearfix"></div>
          </div>
          <div class="text-date">
            <div>登记号：{{item.number}}</div>
            <div>登记日期：{{item.approval_date}}</div>
          </div>
          <div class="text-date">
            <div>创作完成日期：{{item.success_date}}</div>
            <div>首次发布日期：{{item.first_date}}</div>
          </div>
        </div>
        <div class="no-data" style="height: 297px" v-if="copyrightList == null || copyrightList.length == 0">暂无数据</div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg" style="min-height: 349px">
        <div class="title">软件著作权概览</div>
        <div class="cr-list" v-for="(item,index) in copyrightSoftList" v-if="copyrightSoftList != null && copyrightSoftList.length > 0">
          <div class="text-margin">
            <a class="text-title" :title="item.name">{{item.name}}</a>
            <span class="text-label green">{{item.version}}</span>
            <div class="clearfix"></div>
          </div>
          <div class="text-date">
            <div>登记号：{{item.number}}</div>
            <div>软件简称：{{item.short_name}}</div>
          </div>
          <div class="text-date">
            <div>分类号：{{item.type_num}}</div>
            <div>登记批准日期：{{item.approval_date}}</div>
          </div>
        </div>
        <div class="no-data" style="height: 297px" v-if="copyrightSoftList == null || copyrightSoftList.length == 0">暂无数据</div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg">
        <div class="title">作品著作权趋势图</div>
        <div class="chart" id="chart1"></div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg">
        <div class="title">软件著作权趋势图</div>
        <div class="chart" id="chart2"></div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg">
        <div class="title">作品著作权分类图</div>
        <div class="chart" id="chart3"></div>
        <div class="no-data" v-if="copyrightClassifyData == null || copyrightClassifyData.ydataList == null || copyrightClassifyData.ydataList.length == 0">暂无数据</div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg">
        <div class="title">软件著作权分类图</div>
        <div class="chart" id="chart4"></div>
        <div class="no-data" v-if="pieChartResult == null || pieChartResult.serieList == null || pieChartResult.serieList.length == 0">暂无数据</div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg">
        <div class="title">商标概览</div>
        <div class="cs-table">
          <table border="0" cellspacing="0" cellpadding="0" class="main-table">
            <thead>
            <tr>
              <th width="176" class="td-blue">商标</th>
              <th width="76" class="td-blue">注册号</th>
              <th width="76" class="td-blue">申请日期</th>
              <th width="76" class="td-blue">商标类型</th>
              <th width="86" class="td-blue">法律状态</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in trademarkList">
              <td>
                <div class="td-height">
                  <el-image style="width: 40px; height: 40px;vertical-align: middle" :src="item.image_name" fit="contain">
                    <div slot="error" style="text-align: center;font-size: 20px;line-height: 40px;background-color: #f5f7fa;">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <a class="td-trade td-ellipsis" :title="item.name">{{item.name}}</a>
                </div>
              </td>
              <td>
                <div class="td-height text-center">{{item.reg_number}}</div>
              </td>
              <td>
                <div class="td-height text-center">{{item.apply_date}}</div>
              </td>
              <td>
                <div class="td-height text-center">{{item.type_num}}-{{item.type_name}}</div>
              </td>
              <td>
                <a :title="item.status" v-if="item.status == '注册'" class="td-height td-state text-green">{{item.status}}</a>
                <a :title="item.status" v-else-if="item.status == '申请被驳回、不予受理等，该商标已失效'" class="td-height td-state text-red">{{item.status}}</a>
                <a :title="item.status" v-else class="td-height td-state text-warning">{{item.status}}</a>
              </td>
            </tr>
            <tr v-if="trademarkList == null || trademarkList.length == 0">
              <td colspan="5" class="text-center">
                <div style="height: 172px;line-height: 172px">暂无数据</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg">
        <div class="title">专利概览</div>
        <div class="cs-table">
          <table border="0" cellspacing="0" cellpadding="0" class="main-table">
            <thead>
            <tr>
              <th width="80" class="td-blue">专利类型</th>
              <th width="120" class="td-blue">申请公布号</th>
              <th width="76" class="td-blue">发布日期</th>
              <th width="236" class="td-blue">摘要</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in patentList" v-if="patentList != null && patentList.length > 0">
              <td>
                <a class="td-height text-center td-ellipsis" style="max-width: 80px" :title="item.type_name">{{item.type_name}}</a>
              </td>
              <td>
                <div class="td-height text-center">{{item.request_num}}</div>
              </td>
              <td>
                <div class="td-height text-center">{{item.outhor_date}}</div>
              </td>
              <td>
                <a class="td-height" title="摘要" style="line-height: 1.5">{{item.patent_name | spliceTheOverWord}}</a>
              </td>
            </tr>
            <tr v-if="patentList == null || patentList.length == 0">
              <td colspan="4" class="text-center">
                <div style="height: 172px;line-height: 172px">暂无数据</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg">
        <div class="title">工商信息</div>
        <div class="cs-table">
          <table border="0" cellspacing="0" cellpadding="0" class="main-table">
            <tbody>
            <tr>
              <td style="border-top: 1px solid #24436b" class="td-blue">统一社会信用代码</td>
              <td style="border-top: 1px solid #24436b">{{baseInfoObj.creditNo}}</td>
            </tr>
            <tr>
              <td class="td-blue">组织机构代码</td>
              <td>{{baseInfoObj.orgNo}}</td>
            </tr>
            <tr>
              <td class="td-blue">注册号</td>
              <td>{{baseInfoObj.regNo}}</td>
            </tr>
            <tr>
              <td class="td-blue">经营状态</td>
              <td>{{baseInfoObj.status}}</td>
            </tr>
            <tr>
              <td class="td-blue">所属行业</td>
              <td>{{baseInfoObj.domainName}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg" style="height: 267px">
        <div class="title">资质信息</div>
        <div class="cs-table">
          <table border="0" cellspacing="0" cellpadding="0" class="main-table">
            <thead>
            <tr>
              <th class="td-blue">证书类型</th>
              <th class="td-blue">证书编号</th>
              <th class="td-blue" width="76">发证日期</th>
              <th class="td-blue" width="76">证书状态</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in certificateList" v-if="certificateList != null && certificateList.length > 0">
              <td style="font-size: 0">
                <a class="td-ellipsis" :title="item.type">{{item.type}}</a>
              </td>
              <td style="font-size: 0">
                <a class="td-ellipsis" :title="item.num">{{item.num}}</a>
              </td>
              <td>{{item.issue_date}}</td>
              <td style="font-size: 0">
                <div class="text-green td-ellipsis" v-if="item.status == '有效'">{{item.status}}</div>
                <div class="text-warning td-ellipsis" v-else>{{item.status}}</div>
              </td>
            </tr>
            <tr v-if="certificateList == null || certificateList.length == 0">
              <td colspan="4" class="text-center">
                <div style="height: 132px;line-height: 132px">暂无数据</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg">
        <div class="title">同业分析</div>
        <div class="content-p">
          <div class="content-trade">
            <div class="tab-list">
              <div class="item" :class="{active:theTab == index}" @click="selTab(index)" v-for="(item,index) in tablist">{{item}}</div>
            </div>
            <div class="chart" id="chart5" style="height: 255px"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-item">
      <div class="bg">
        <div class="title">社保人数趋势图</div>
        <div class="chart" id="chart6"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import echarts from 'echarts'
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";
  import headCompany from "./headCompany";
  import $ from 'jquery'

  Vue.prototype.$echarts = echarts
  export default {
    name: "index",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        text:'摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要',
        theTab:1,
        tablist:['作品著作权数量','软件著作权数量','商标数量','专利数量'],

        creditNo:"",//企业全称

        copyrightSoftList:[],//软件著作权概览
        lineChartResult:{},//软件著作权趋势
        pieChartResult:{},//软件著作权分类
        socialLineData: {},//社保信息
        baseInfoObj:{},//工商照面

        copyrightList:[],//著作权列表
        copyrightTrendsData:{},//著作权趋势
        copyrightClassifyData:{},//著作权分类

        trademarkList:[],//商标列表
        patentList:[],//专利列表
        certificateList:[],//资质列表
        tradeAnalysisData:0,//同业分析,
      }
    },
    watch: {
      $route: { // 亲测有效,我是用来监听query参数变化
        handler() {
          //深度监听，同时也可监听到param参数变化
          this.creditNo = this.$route.query.creditNo
          console.info("加载企业概览信息："+this.$route.query.creditNo)

          //软件著作权统计-软件著作权列表、软件著作权趋势、软件著作权分类
          this.getCopyrightSoftStatistics()
          //社保人数趋势
          this.getSocialSecurityByName()
          //工商信息
          this.getBasicInfo()

          //著作权统计-著作权列表、著作权趋势、著作权分类
          this.getCopyrightStatistics()
          //商标列表
          this.getTrademarkList()
          //专利列表
          this.getPatentList()
          //资质列表
          this.getCertificateList()
          //同业分析
          this.selTab(0)
        },
        deep: true,
      }
    },
    mounted() {
      let creditNo = null
      if (this.$route.query.creditNo != null) creditNo = decodeURI(this.$route.query.creditNo)
      if (creditNo != null && creditNo.length > 0) {
        console.info("初次加载企业概览信息："+creditNo)
        this.creditNo = creditNo
        //软件著作权统计-软件著作权列表、软件著作权趋势、软件著作权分类
        this.getCopyrightSoftStatistics()
        //社保人数趋势
        this.getSocialSecurityByName()
        //工商信息
        this.getBasicInfo()

        //著作权统计-著作权列表、著作权趋势、著作权分类
        this.getCopyrightStatistics()
        //商标列表
        this.getTrademarkList()
        //专利列表
        this.getPatentList()
        //资质列表
        this.getCertificateList()
        //同业分析
        this.selTab(0)
      }

      this.myresize();
    },
    filters: {
      spliceTheOverWord(str) {
        if (str.length > 33) {
          return str.substr(0, 33) + '...'
        } else {
          return str
        }
      }
    },
    methods:{
      //软件著作权列表
      getCopyrightSoftList() {
        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        form.countPerPage = 3;
        form.orderBy = "theYearMonthDay desc";
        ServerInterface.post("CopyrightSoftForLocalList.html", form, (obj) => {
          this.copyrightSoftList = obj.copyrightSoftList;
        })
      },
      //软件著作权统计
      getCopyrightSoftStatistics() {
        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        ServerInterface.post("CopyrightSoftStatistics.html", form, (obj) => {
          let statisticsData = JSON.parse(obj.statisticsData);
          this.lineChartResult = statisticsData.lineChartResult;
          this.pieChartResult = statisticsData.pieChartResult;
          this.copyrightSoftLine();
          if (this.pieChartResult == null || this.pieChartResult.serieList == null || this.pieChartResult.serieList.length == 0) {
            $("#chart4").css('display','none')
          }
          else
          {
            $("#chart4").css('display','block')
          }
          this.copyrightSoftPie();
          this.getCopyrightSoftList();
        })
      },
      //社保人数
      getSocialSecurityByName() {
        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        ServerInterface.post("GetSocialSecurityByName.html", form, (obj) => {
          if (obj.xdataList.length == 0) {
            obj.xdataList = ['2013','2014','2015','2016','2017','2018','2019'];
            obj.ydataList = [0,0,0,0,0,0,0];
          }
          this.socialLineData = obj;
          this.socialLine();
        })
      },
      //工商信息
      getBasicInfo() {
        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        ServerInterface.post("GetBasicInfo.html", form, (obj) => {
          this.baseInfoObj = JSON.parse(obj.data);
        })
      },
      //著作权统计
      getCopyrightStatistics() {
        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        ServerInterface.post("CopyrightStatistics.html", form, (obj) => {
          let statisticsData = JSON.parse(obj.statisticsData);
          this.copyrightTrendsData = statisticsData.copyrightTrendsData;
          this.copyrightClassifyData = statisticsData.copyrightClassifyData;
          this.copyrightLine();
          if (this.copyrightClassifyData == null || this.copyrightClassifyData.ydataList == null || this.copyrightClassifyData.ydataList.length == 0) {
            $("#chart3").css('display','none')
          }
          else
          {
            $("#chart3").css('display','block')
          }
          this.copyrightPie();
          this.getCopyrightList();
        })
      },
      //著作权列表
      getCopyrightList() {
        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        form.countPerPage = 3;
        form.orderBy = "theYearMonthDay desc";
        ServerInterface.post("CopyrightForLocalList.html", form, (obj) => {
          this.copyrightList = obj.copyrightList;
        })
      },
      //商标列表
      getTrademarkList() {
        let countPerPage = 3;

        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        ServerInterface.post("TrademarkList.html", form, (obj) => {
          let trademarkList = JSON.parse(obj.data).items
          if (trademarkList != null && trademarkList.length >= countPerPage) {
            this.trademarkList = trademarkList.slice(0, countPerPage)
          }
          else {
            this.trademarkList = trademarkList
          }
        })
      },
      //专利列表
      getPatentList() {
        let countPerPage = 3;

        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        ServerInterface.post("PatentList.html", form, (obj) => {
          let patentList = JSON.parse(obj.data).items
          if (patentList != null && patentList.length >= countPerPage) {
            this.patentList = patentList.slice(0, countPerPage)
          }
          else {
            this.patentList = patentList
          }
        })
      },
      //资质列表
      getCertificateList() {
        let countPerPage = 4;

        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        ServerInterface.post("CertificateList.html", form, (obj) => {
          let certificateList = JSON.parse(obj.data).items
          if (certificateList != null && certificateList.length >= countPerPage) {
            this.certificateList = certificateList.slice(0, countPerPage)
          }
          else {
            this.certificateList = certificateList
          }
        })
      },
      //同业分析
      getTradeAnalysisStatistics(sourceType) {
        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        form.sourceType = sourceType;
        ServerInterface.post("TradeAnalysisStatistics.html", form, (obj) => {
          this.tradeAnalysisData = obj.data
          //绘制仪表盘
          this.chartGauge()
        })
      },
      selTab(index){
        this.theTab = index;
        if (index == 0) {
          //著作权数量
          this.getTradeAnalysisStatistics(1)
        } else if (index == 1) {
          //软件著作权数量
          this.getTradeAnalysisStatistics(2)
        } else if (index == 2) {
          //商标数量
          this.getTradeAnalysisStatistics(3)
        } else if (index == 3) {
          //专利数量
          this.getTradeAnalysisStatistics(4)
        }
      },
      myresize() {
        let copyrightChart = echarts.init(document.getElementById('chart1'));
        let copyrightSoftChart = echarts.init(document.getElementById('chart2'));
        let copyrightPie = echarts.init(document.getElementById('chart3'));
        let copyrightSoftPie = echarts.init(document.getElementById('chart4'));
        let chartGauge = echarts.init(document.getElementById('chart5'));
        let socialLine = echarts.init(document.getElementById('chart6'));
        window.onresize = function () {
          copyrightChart.resize()
          copyrightSoftChart.resize()
          copyrightPie.resize()
          copyrightSoftPie.resize();
          chartGauge.resize()
          socialLine.resize()
        }
      },
      // 著作权趋势图
      copyrightLine() {
        let copyrightChart = echarts.init(document.getElementById('chart1'));
        copyrightChart.setOption({
          color: ['#1890ff'],
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: '3%',
            right: '3%',
            bottom: '6%',
            top: '7%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              textStyle: {
                color: '#8fb9f1', // x轴颜色
              }
            },
            axisLine: {
              lineStyle: {
                color: '#24436b',
              }
            },
            data: this.copyrightTrendsData.xdataList
          },
          yAxis: {
            type: 'value',
            minInterval: 1,
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#8fb9f1', // y轴颜色
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color:'#24436b'
              }
            },
          },
          series: [{
              name: '著作权',
              type: 'line',
              symbol:'circle',
              symbolSize: 8,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(24, 144, 255,1)'
                }, {
                  offset: 1,
                  color: 'rgba(24, 144, 255,0)'
                }])
              },
              data: this.copyrightTrendsData.ydataList
            }]
        })
      },
      // 软件著作权趋势图
      copyrightSoftLine() {
        let copyrightSoftChart = echarts.init(document.getElementById('chart2'));
        copyrightSoftChart.setOption({
          color: ['#12d0d2'],
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: '3%',
            right: '3%',
            bottom: '6%',
            top: '7%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              textStyle: {
                color: '#8fb9f1', // x轴颜色
              }
            },
            axisLine: {
              lineStyle: {
                color: '#24436b',
              }
            },
            data: this.lineChartResult.xdataList
          },
          yAxis: {
            type: 'value',
            minInterval: 1,
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#8fb9f1', // y轴颜色
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color:'#24436b'
              }
            },
          },
          series: [{
            name: '软件著作权',
            type: 'line',
            symbol:'circle',
            symbolSize: 8,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(18, 208, 210,1)'
              }, {
                offset: 1,
                color: 'rgba(18, 208, 210,0)'
              }])
            },
            data: this.lineChartResult.ydataList
          }]
        })
      },
      // 著作权分类图
      copyrightPie(){
        let copyrightPie = echarts.init(document.getElementById('chart3'));
        let colorList = ['#12d0d2', '#0092f1', '#6dd48c', '#fbd437', '#ffbf44', '#f2637b']
        let arr=this.copyrightClassifyData.ydataList
        copyrightPie.setOption({
          color:colorList,
          title: {
            x: 'center',
            y: 'center',
          },
          tooltip: {
            trigger: 'item'
          },
          series: [{
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['40%', '60%'],
            clockwise: true,
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} \n {d}%',
            },
            labelLine: {
              normal: {
                length: 20,
                length2: 30,
                lineStyle: {
                  width: 1
                }
              }
            },
            data: arr,
          }]
        });
      },
      // 软件著作权分类图
      copyrightSoftPie(){
        let copyrightSoftPie = echarts.init(document.getElementById('chart4'));
        let colorList = ['#12d0d2', '#0092f1', '#6dd48c', '#fbd437', '#ffbf44', '#f2637b']
        let arr = this.pieChartResult.serieList
        copyrightSoftPie.setOption({
          color:colorList,
          title: {
            x: 'center',
            y: 'center',
          },
          tooltip: {
            trigger: 'item'
          },
          series: [{
            type: 'pie',
            center: ['50%', '50%'],
            radius: '55%',
            clockwise: true,
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} \n {d}%',
            },
            labelLine: {
              normal: {
                length: 20,
                length2: 30,
                lineStyle: {
                  width: 1
                }
              }
            },
            data: arr,
          }]
        });
      },
      // 同业分析
      chartGauge(){
        let chartGauge = echarts.init(document.getElementById('chart5'));
        chartGauge.setOption({
          tooltip: {
            position: "inside",
            formatter: "小于40%为偏低<br>40%~60%为中等<br>大于60%为优秀",
            textStyle: {
              fontSize: 10,
            },
          },
          series: [
            {
              name: "刻度",
              type: "gauge",
              center: ["60%", "75%"],
              radius: '90%',
              min: 0, //最小刻度
              max: 100, //最大刻度
              splitNumber: 5, //刻度数量
              startAngle: 180,
              endAngle: 0,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: [[1, "rgba(0,0,0,0)"]],
                },
              }, //仪表盘轴线
              axisLabel: {
                show: true,
                color: "#fff",
                fontSize: 15,
                distance: -50,
                formatter: function (v) {
                  return v + "%";
                },
              }, //刻度标签。
              axisTick: {
                show: true,
                splitNumber: 5,
                lineStyle: {
                  color: "#fff",
                  width: 1,
                },
                length: -3,
              }, //刻度样式
              splitLine: {
                show: true,
                length: -5,
                lineStyle: {
                  color: "#fff",
                },
              }, //分隔线样式
            },
            {
              type: "gauge",
              radius: '80%',
              center: ["60%", "75%"],
              splitNumber: 0, //刻度数量
              startAngle: 180,
              endAngle: 0,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 50,
                  color: [
                    [0.2, "#00a65a"],
                    [0.4, "#2b64fc"],
                    [0.6, "#f39c11"],
                    [1, "#fa4e4b"],
                  ],
                },
              },
              //分隔线样式。
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              pointer: {
                show: true,
                length: "80%",
                width: "2%",
              },
              title: {
                show: true,
                offsetCenter: [-10, "30"], // x, y，单位px
                textStyle: {
                  fontWeight: "bold",
                  color: "#fff",
                  fontSize: 13,
                },
              },
              //仪表盘详情，用于显示数据。
              detail: {
                show: true,
                offsetCenter: [70, "30"],
                color: "#fff",
                formatter: function(params){
                  return params.toFixed(2) + '%'
                },
                textStyle: {
                  fontSize: 13,
                  fontWeight: "bold",
                },
                // formatter: function (value) {
                //   if (value <= 20) {
                //     return "有发展空间";
                //   } else if (value <= 40) {
                //     return "健康";
                //   } else if (value <= 60) {
                //     return "预警";
                //   } else {
                //     return "过度";
                //   }
                // },
              },
              data: [
                {
                  name: "在全部企业中占比：",//在全国同行企业中占比：
                  value: this.tradeAnalysisData,
                },
              ],
            },
            {
              // 仅显示tooltip用
              type: "pie",
              radius: "90%",
              hoverAnimation: true,
              label: {
                normal: {
                  show: false,
                  position: "center",
                },
              },
              labelLine: {
                normal: {
                  show: false,
                },
              },
              z: 3,
              zlevel: 0,
              itemStyle: {
                borderWidth: 0,
              },
              data: [
                {
                  value: 50,
                  name: "",
                  label: {
                    normal: {
                      show: false,
                    },
                  },
                  itemStyle: {
                    normal: {
                      color: "transparent",
                    },
                  },
                },
              ],
            },
          ],
        })
      },
      //社保人数趋势图
      socialLine() {
        let socialLineChart = echarts.init(document.getElementById('chart6'));
        socialLineChart.setOption({
          color: ['#ffc844'],
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: '3%',
            right: '3%',
            bottom: '6%',
            top: '7%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              textStyle: {
                color: '#8fb9f1', // x轴颜色
              }
            },
            axisLine: {
              lineStyle: {
                color: '#24436b',
              }
            },
            data: this.socialLineData.xdataList
          },
          yAxis: {
            type: 'value',
            minInterval: 1,
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#8fb9f1', // y轴颜色
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color:'#24436b'
              }
            },
          },
          series: [{
            name: '社保人数',
            type: 'line',
            symbol:'circle',
            symbolSize: 8,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 200, 68,1)'
              }, {
                offset: 1,
                color: 'rgba(255, 200, 68,0)'
              }])
            },
            data: this.socialLineData.ydataList
          }]
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @borderColor:#24436b;
  @bgColor:#02052c;
  .no-data{
    text-align: center;
    height: 281px;
    line-height: 281px;
    color: #fff;
  }
  .text-green{
    color: #68cb88;
  }
  .text-red{
    color: #eb4260;
  }
  .text-warning{
    color: #ebe710;
  }
  .text-center{
    text-align: center;
  }
  .cs-item{
    font-size: 13px;
    width: 50%;
    padding: 8px;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    .bg{
      background-color: @bgColor;
      box-shadow: 0 1px 7px 0 rgba(0,0,0,.1);
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #1a3d7e;
      overflow: hidden;
    }
    .title{
      color: #00ccff;
      background-image: linear-gradient(to right, #104dac, #141f55);
      padding: 15px 10px;
      font-weight: 600;
      font-size: 15px;
      &:before{
        content: '';
        display: inline-block;
        width: 3px;
        height: 18px;
        background-color: #00ccff;
        border-radius: 4px;
        vertical-align: middle;
        margin-right: 10px;
        margin-top: -3px;
      }
    }
    .chart{
      width: 100%;
      height: 281px;
    }
    .cr-list:last-child{
      border-bottom: none;
    }
  }
  /*概览*/
  .cr-list{
    padding: 12px;
    border-bottom: 1px solid #0d448c;
    &:nth-child(2n+1){
      background-color: #031d4a;
    }
    .text-margin{
      margin-bottom: 7px;
    }
    .text-title{
      font-size: 15px;
      display: inline-block;
      width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #fff;
    }
    .text-label{
      float: right;
      color: #090e3e;
      background-color: #00d2ff;
      padding: 1px 5px;
      border-radius: 2px;
      &.green{
        background-color: #11c3b3;
        color: #14325d;
      }
    }
    .text-date{
      display: inline-block;
      width: 50%;
      line-height: 1.7;
      color: #8fb9f1;
    }
  }
  /*商标*/
  .cs-table{
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 12px;
    box-sizing: border-box;
  }
  .main-table{
    width: 100%;
    table-layout: fixed;
    td,th{
      padding: 10px 12px;
      color: #fff;
      border-bottom: 1px solid @borderColor;
      border-right: 1px solid @borderColor;
    }
    td:first-child{
      border-left: 1px solid @borderColor;
    }
    th{
      border-top: 1px solid @borderColor;
      &:first-child{
        border-left: 1px solid @borderColor;
      }
    }
    .td-height{
      height: 40px;
      line-height: 40px;
      overflow: hidden;
    }
    .td-ellipsis{
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      max-width: 100%;
    }
    .td-state{
      text-align: center;
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
    .td-trade{
      vertical-align: middle;
      margin-left: 7px;
      max-width: 135px;
    }
  }
  /*工商信息*/
  .td-blue{
    background: #0f77d4;
    color: #fff;
  }
  /*同业分析*/
  .content-p{
    padding: 12px;
  }
  .content-trade{
    border: 1px solid @borderColor;
    position: relative;
  }
  .tab-list{
    display: inline-block;
    position: absolute;
    z-index: 10;
    color: #fff;
    height: 255px;
    border-right: 1px solid @borderColor;
    background-color: #141f55;
    .active{
      background-color: #0f77d4;
    }
    .item{
      padding: 23px 15px;
      cursor: pointer;
      border-bottom: 1px solid @borderColor;
      &:last-child{
        border-bottom: none;
      }
    }
  }
</style>
