<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title" v-if="theTab == 1">域名信息</div>
        <div class="trade-title" v-else-if="theTab == 2">资质信息</div>
        <div class="trade-title" v-else-if="theTab == 3">高薪技术企业</div>
        <div class="trade-num">找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <ul class="list-tab">
        <li :class="{active:theTab == 1}" @click="changeTab(1)">域名信息</li>
        <li :class="{active:theTab == 2}" @click="changeTab(2)">资质信息</li>
        <li :class="{active:theTab == 3}" @click="changeTab(3)">高薪技术企业</li>
      </ul>
<!--      域名信息-->
      <el-table :data="listData1" border stripe style="width: 100%" height="calc(100vh - 384px)" v-show="theTab == 1" ref="multipleTable1">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="company" label="企业名称" width="160"></el-table-column>
        <el-table-column prop="ename" label="命名企业名称" width="160"></el-table-column>
        <el-table-column prop="type" label="主办单位性质" width="140"></el-table-column>
        <el-table-column prop="home_url" label="主页URL" width="180"></el-table-column>
        <el-table-column prop="domain" label="域名" min-width="180"></el-table-column>
        <el-table-column prop="number" label="网站备案许可号" width="160"></el-table-column>
        <el-table-column prop="site_name" label="网站名称" width="160"></el-table-column>
        <el-table-column prop="check_date" label="核查日期" width="120" align="center"></el-table-column>
      </el-table>
<!--      资质信息-->
      <el-table :data="listData2" border stripe style="width: 100%" height="calc(100vh - 419px)" v-show="theTab == 2" ref="multipleTable2">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="资质证书类型" min-width="180"></el-table-column>
        <el-table-column prop="num" label="证书编号" width="230"></el-table-column>
        <el-table-column prop="status" label="证书状态" width="180" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status == '含过期'" class="btn-td warning">{{scope.row.status}}</div>
            <div v-else-if="scope.row.status == '已注销' || scope.row.status == '已撤销'" class="btn-td red">{{scope.row.status}}</div>
            <div v-else-if="scope.row.status == '有效'" class="btn-td green">{{scope.row.status}}</div>
            <div v-else class="btn-td warning">{{scope.row.status}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" min-width="120"></el-table-column>
        <el-table-column prop="issue_date" label="发证日期" width="120" align="center"></el-table-column>
        <el-table-column prop="validity_end" label="截止日期" width="120" align="center"></el-table-column>
      </el-table>
<!--      高新技术企业-->
      <el-table :data="listData3" border stripe style="width: 100%" height="calc(100vh - 419px)" v-show="theTab == 3" ref="multipleTable3">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="register_no" label="证书编号" min-width="200"></el-table-column>
        <el-table-column prop="valid_start" label="发证日期" min-width="120" align="center"></el-table-column>
        <el-table-column prop="valid_end" label="截止日期" min-width="120" align="center"></el-table-column>
        <el-table-column prop="state" label="状态" min-width="120" align="center">
          <template slot-scope="scope">
            <!--<div v-if="scope.row.state == '含过期'" class="btn-td warning">{{scope.row.state}}</div>
            <div v-else-if="scope.row.state == '已注销' || scope.row.status == '已撤销'" class="btn-td red">{{scope.row.state}}</div>
            <div v-else-if="scope.row.state == '有效'" class="btn-td green">{{scope.row.state}}</div>-->
            <div>{{scope.row.state}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="备注" min-width="200"></el-table-column>
      </el-table>
     <div class="cs-page" v-show="theTab == 2 || theTab == 3">
        <el-pagination
            @size-change="handleSizeChange"
            background
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNumber"
            :page-size="pagination.countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="pagination.totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import listMixin from "../mixin/listMixin";
  import ServerInterface from "../js/common/ServerInterface";

  export default {
    name: "qualificationList",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        listData1:[],
        listData2:[],
        listData3:[],
        theTab:1,
      }
    },
    mounted() {
      this.getList()
    },
    methods:{
      handleSizeChange(val) {
        this.pagination.countPerPage = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pagination.pageNumber = val
        this.getList()
      },
      getList() {
        let url = ""
        if (this.theTab == 1) {
          //域名信息
          url = "DomainList.html"
        } else if (this.theTab == 2) {
          //资质信息
          url = "CertificateList.html"
        } else if (this.theTab == 3) {
          //高新技术企业资质
          url = "GxList.html"
        }

        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo
        ServerInterface.post(url, form, (obj) => {
          this.refreshPagination(obj)
          if (this.theTab == 1) {
            this.listData1 = JSON.parse(obj.data).items
          } else if (this.theTab == 2) {
            this.listData2 = JSON.parse(obj.data).items
          } else if (this.theTab == 3) {
            this.listData3 = JSON.parse(obj.data).items
          }
        })
      },
      changeTab(theTab) {
        this.theTab = theTab
        this.pagination.pageNumber = 1

        this.$nextTick(function(){
          if (this.theTab == 1) {
            this.$refs.multipleTable1.doLayout();
          } else if (this.theTab == 2) {
            this.$refs.multipleTable2.doLayout();
          } else if (this.theTab == 3) {
            this.$refs.multipleTable3.doLayout();
          }
        })

        this.getList()
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
  .list-tab{
    font-size: 13px;
    color: #fff;
    .active{
      background-color: #508fff;
    }
    li{
      display: inline-block;
      border: 1px solid #043771;
      padding: 10px 20px;
      margin-right: -1px;
      cursor: pointer;
    }
  }
</style>
