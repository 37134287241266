import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/main.less'
// 引用全局过滤器
import dfilters from './js/common/GlobalFilters.js'

// 将全局过滤器注册到当前页面的Vue中
Object.keys(dfilters).forEach(key => {
  // console.log(filters[key])
  Vue.filter(key,dfilters[key])
})


Vue.config.productionTip = false
Vue.use(require('vue-wechat-title'))
import {Message,Pagination,Table,TableColumn,Image} from "element-ui";
Vue.prototype.$message = Message
Vue.use(Pagination)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Image)

new Vue({
  router: router,//路由设置
  render: h => h(App),
}).$mount('#app')
