<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total" v-if="this.theTab == 1">
        <div class="trade-title">作品著作权信息</div>
        <div class="trade-num">找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
      <div class="trade-total" v-if="this.theTab == 2">
        <div class="trade-title">软件著作权信息</div>
        <div class="trade-num">找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <ul class="list-tab">
        <li :class="{active:theTab == 1}" @click="changeTab(1)">作品著作权</li>
        <li :class="{active:theTab == 2}" @click="changeTab(2)">软件著作权</li>
      </ul>
      <el-table :data="listData" border stripe style="width: 100%" height="calc(100vh - 419px)" v-if="theTab == 1">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="作品名称" min-width="200"></el-table-column>
        <el-table-column prop="number" label="登记号" min-width="200"></el-table-column>
        <el-table-column prop="type_name" label="类别" width="130" align="center"></el-table-column>
        <el-table-column prop="success_date" label="创作完成日期" width="120" align="center"></el-table-column>
        <el-table-column prop="approval_date" label="登记日期" width="120" align="center"></el-table-column>
        <el-table-column prop="first_date" label="首次发布日期" width="120" align="center"></el-table-column>
      </el-table>
      <el-table :data="listData" border stripe style="width: 100%" height="calc(100vh - 419px)" v-if="theTab == 2">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="软件名称" min-width="200"></el-table-column>
        <el-table-column prop="number" label="登记号" min-width="200"></el-table-column>
        <el-table-column prop="version" label="版本号" width="130" align="center"></el-table-column>
        <el-table-column prop="type_num" label="分类号" width="120" align="center"></el-table-column>
        <el-table-column prop="first_date" label="登记批准日期" width="120" align="center"></el-table-column>
        <el-table-column prop="short_name" label="软件简称" width="120" align="center"></el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            @size-change="handleSizeChange"
            background
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNumber"
            :page-size="pagination.countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="pagination.totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import listMixin from "../mixin/listMixin";
  import ServerInterface from "../js/common/ServerInterface";

  export default {
    name: "copyrightList",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        theTab:1,
        listData:[],
      }
    },
    mounted() {
      this.getList()
    },
    methods:{
      handleSizeChange(val) {
        this.pagination.countPerPage = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pagination.pageNumber = val
        this.getList()
      },
      toDetail(){
        this.$router.push({
          path: `/patentDetail`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      changeTab(theTab) {
        this.theTab = theTab
        this.pagination.pageNumber = 1
        this.getList()
      },
      getList() {
        let url = null
        if (this.theTab == 1) {
          //著作权
          url = "CopyrightList.html"
        }
        else if (this.theTab == 2) {
          //软件著作权
          url = "CopyrightSoftList.html"
        }

        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo
        ServerInterface.post(url, form, (obj) => {
          this.refreshPagination(obj)
          this.listData = JSON.parse(obj.data).items
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
  .list-tab{
    font-size: 13px;
    color: #fff;
    .active{
      background-color: #508fff;
    }
    li{
      display: inline-block;
      border: 1px solid #043771;
      padding: 10px 20px;
      margin-right: -1px;
      cursor: pointer;
    }
  }
</style>
