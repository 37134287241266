import ServerInterface from "../js/common/ServerInterface"
import Util from "../js/common/Util";

export default {
  data() {
    return {
      pagination: {
        pageNumber: 1,
        countPerPage: 20,
        totalCount: undefined,
        totalPage: undefined,
        keyword: undefined,
        // orderBy: undefined,
      },
      isEdit: true,
      isDel: true,
      multipleSelection: [],
    }
  },
  watch: {
    pagination: {
      handler(newVal, oldVal) {
        if (newVal.keyword !== undefined && newVal.keyword === "") {
          this.getList()
        }
      },
      deep: true
    }
  },
  methods: {
    getBaseForm() {
      const form = Util.getBaseForm()
      return form
    },
    getPaginationForm() {
      const baseForm = this.getBaseForm()
      const form = {
        ...baseForm,
        ...this.pagination
      }
      return form
    },
    refreshPagination(obj) {
      this.pagination.totalCount = obj.totalCount
      this.pagination.totalPage = obj.totalPage
      this.pagination.pageNumber = obj.pageNumber
      this.pagination.countPerPage = obj.countPerPage
    },
    search() {
      // this.pagination.pageNumber = 1
      this.getList()
    },
    getList() {
      // INFO: Dechert: 2020/6/29 需要进行重写的方法，这里的方法体就是空的
      console.log('getList method need to be overwrote')
    },
    handleChangePage(num) {
      this.pagination.pageNumber = num
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length === 1) {
        this.isEdit = false;
      } else {
        this.isEdit = true;
      }
      if (this.multipleSelection.length >= 1) {
        this.isDel = false;
      } else {
        this.isDel = true;
      }
    },
    changeSort(val) {
      console.log('changeSort')
      let sort = "";
      if ("descending" == val.order) {
        sort = "desc";
      } else {
        sort = "asc";
      }
      // this.orderByField = val.prop;
      // this.orderByType = sort;
      this.pagination.orderBy=`${val.prop} ${sort}`
      //不请求后台只在当前页排序
      this.getList();
    },

  },
}
