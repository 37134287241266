<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">企业联系方式</div>
        <div class="trade-num">共 <span>1</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <table class="detail-table" cellpadding="0" cellspacing="0" border="0">
        <tbody>
          <tr>
            <td class="td-title">企业注册号：</td>
            <td colspan="5">{{contactInfoObj.regNo}}</td>
          </tr>
          <tr>
            <td class="td-title">统一社会信用代码：</td>
            <td colspan="5">{{contactInfoObj.creditNo}}</td>
          </tr>
          <tr>
            <td class="td-title">电话：</td>
            <td colspan="2">{{contactInfoObj.telephone}}</td>
            <td class="td-title">邮件：</td>
            <td colspan="2">{{contactInfoObj.email}}</td>
          </tr>
          <tr>
            <td class="td-title">地址：</td>
            <td colspan="5">{{contactInfoObj.address}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "contactInfo",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        contactInfoObj:{}
      }
    },
    mounted() {
      this.getBasicInfo();
    },
    methods:{
      getBasicInfo() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        ServerInterface.post("GetContactInfo.html", form, (obj) => {
          this.contactInfoObj = JSON.parse(obj.data);
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
  @import "src/assets/css/detail";
</style>
