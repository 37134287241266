<template>
  <div>
    <div class="company-wrap">
      <div style="padding: 15px 20px 15px 20px">
        <div class="text">{{baseInfoObj.name | firstLetterHandler}}</div>
        <div class="company-info">
          <h1>{{baseInfoObj.name}}</h1>
          <span v-if="baseInfoObj.tags == '1'">新三板</span>
          <span v-if="baseInfoObj.tags == '6'">主板上市公司</span>
          <span v-if="baseInfoObj.tags == '40'">暂停上市</span>
          <span v-if="baseInfoObj.tags == '41'">终止上市</span>
          <span v-if="baseInfoObj.tags == '9'">香港上市</span>
          <span v-if="baseInfoObj.tags == '17'">高新企业</span>
          <span v-if="baseInfoObj.tags == null || baseInfoObj.tags == ''">其它</span>
        </div>
        <div class="clearfix"></div>
      </div>
      <ul class="company-tab">
        <li class="level-one" :class="{'active' : nowPath == '/'}" @click="toOverView"><span>概况</span></li>
        <li class="level-one" :class="{'active' : nowPath == '/tradeMarkList' || nowPath == '/patentList' || nowPath == '/copyrightList' || nowPath == '/qualificationList' || nowPath == '/knowledgeList'}">
          <span>知识产权</span>
          <ul class="level-two">
            <li @click="toTradeMark" :class="{'active' : nowPath == '/tradeMarkList'}">商标</li>
            <li @click="toPatent" :class="{'active' : nowPath == '/patentList'}">专利</li>
            <li @click="toCopyright" :class="{'active' : nowPath == '/copyrightList'}">著作权</li>
            <li @click="toQualificationList" :class="{'active' : nowPath == '/qualificationList'}">资质认证</li>
            <li @click="toKnowledgeList" :class="{'active' : nowPath == '/knowledgeList'}">知识产权出质</li>
          </ul>
        </li>
        <li class="level-one" :class="{'active' : nowPath == '/basicInfo' || nowPath == '/partnersList' || nowPath == '/employeesList' || nowPath == '/contactInfo' || nowPath == '/websitesList' || nowPath == '/entBriefByName' || nowPath == '/socialSecurityList' || nowPath == '/investmentList' || nowPath == '/mortgagesList' || nowPath == '/qualitiesList'}">
          <span>工商信息</span>
          <ul class="level-two">
            <li :class="{'active' : nowPath == '/basicInfo'}" @click="toBasicInfo">工商照面</li>
            <li :class="{'active' : nowPath == '/partnersList'}" @click="toPartners">工商股东</li>
            <li :class="{'active' : nowPath == '/employeesList'}" @click="toEmployees">主要人员</li>
            <li :class="{'active' : nowPath == '/contactInfo'}" @click="toContactInfo">企业联系方式</li>
            <li :class="{'active' : nowPath == '/websitesList'}" @click="toWebsites">企业年报网址</li>
            <li :class="{'active' : nowPath == '/entBriefByName'}" @click="toEntBriefByName">企业简介</li>
            <li :class="{'active' : nowPath == '/socialSecurityList'}" @click="toSocialSecurity">社保信息</li>
            <li :class="{'active' : nowPath == '/investmentList'}" @click="toInvestment">对外投资</li>
            <li :class="{'active' : nowPath == '/mortgagesList'}" @click="toMortgages">动产抵押</li>
            <li :class="{'active' : nowPath == '/qualitiesList'}" @click="toQualities">股权出质</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "headCompany",
    mixins: [listMixin],
    data(){
      return{
        baseInfoObj:{},//工商照面
        nowPath:"/",//当前引用该子组件的父组件路由
      }
    },
    watch: {
      $route: { // 亲测有效,我是用来监听query参数变化
        handler() {
          //深度监听，同时也可监听到param参数变化
          this.creditNo = this.$route.query.creditNo
          // console.info("加载企业概览信息："+this.$route.query.creditNo)
          //工商信息
          this.getBasicInfo()
        },
        deep: true,
      }
    },
    mounted() {
      this.nowPath = this.$route.path
      console.log("当前路由："+this.nowPath)

      let creditNo = null
      if (this.$route.query.creditNo != null) creditNo = this.$route.query.creditNo
      if (creditNo != null && creditNo.length > 0) {
        // console.info("初次加载企业概览信息："+creditNo)
        this.creditNo = creditNo
        //工商信息
        this.getBasicInfo()
      }
    },
    methods:{
      toOverView() {
        this.$router.push({
          path: `/`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toTradeMark(){
        this.$router.push({
          path: `/tradeMarkList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toPatent(){
        this.$router.push({
          path: `/patentList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toCopyright(){
        this.$router.push({
          path: `/copyrightList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toQualificationList(){
        this.$router.push({
          path: `/qualificationList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toKnowledgeList(){
        this.$router.push({
          path: `/knowledgeList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toBasicInfo(){
        this.$router.push({
          path: `/basicInfo`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toPartners(){
        this.$router.push({
          path: `/partnersList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toEmployees(){
        this.$router.push({
          path: `/employeesList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toContactInfo(){
        this.$router.push({
          path: `/contactInfo`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toWebsites(){
        this.$router.push({
          path: `/websitesList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toEntBriefByName(){
        this.$router.push({
          path: `/entBriefByName`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toSocialSecurity(){
        this.$router.push({
          path: `/socialSecurityList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toInvestment(){
        this.$router.push({
          path: `/investmentList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toQualities(){
        this.$router.push({
          path: `/qualitiesList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      toMortgages(){
        this.$router.push({
          path: `/mortgagesList`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
      //工商信息
      getBasicInfo() {
        let form = this.getPaginationForm();
        form.creditNo = this.creditNo;
        ServerInterface.post("GetBasicInfo.html", form, (obj) => {
          this.baseInfoObj = JSON.parse(obj.data);
        })
      },
    }
  }
</script>

<style scoped lang="less">
  .company-wrap{
    padding: 0 8px 8px 8px;
    .text{
      width: 80px;
      height: 80px;
      line-height: 80px;
      font-size: 40px;
      color: #fff;
      text-align: center;
      border-radius: 4px;
      background: #01bff5;
      float: left;
    }
    .company-info{
      margin-left: 100px;
      h1{
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        margin-top: 0;
      }
      span{
        background: #ffda5b;
        color: #081f66;
        font-size: 13px;
        border-radius: 2px;
        padding:3px 7px;
      }
    }
  }
  .company-tab{
    .level-one{
      color: #fff;
      font-size: 16px;
      display: inline-block;
      padding: 15px 30px;
      width: 140px;
      box-sizing: border-box;
      text-align: center;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      position: relative;
      &.active{
        span{
          color: #2fa4ff;
        }
        border-bottom: 3px solid #2fa4ff;
      }
      &:hover{
        span{
          color: #2fa4ff;
        }
        border-bottom: 3px solid #2fa4ff;
        .level-two{
          display: block;
        }
      }
      .level-two{
        background-color: #cce4ff;
        color: #020546;
        position: absolute;
        width: 140px;
        top: 54px;
        left: 0;
        display: none;
        z-index: 10;
        li{
          padding: 13px 20px;
          text-align: left;
          &:hover{
            background-color: #fff;
          }
          &.active{
            background-color: #fff;
          }
        }
      }
    }
  }
</style>
