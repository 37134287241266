/**
 * 在该文件中定义全局过滤器，在需要用到过滤器的页面对应的 main.js 中引入， 并注册全局过滤器
 */
const dfilters = {
    /**
     * 空值处理
     */
    valNullHandler : function(value, placeholder) {
        if (value == null || (value+"").length < 1) return placeholder ? placeholder:"-";
        else return value;
    },
    /**
     * 获取字符串的首字符
     */
    firstLetterHandler: (value) => {
        if (value == null || value == undefined || value.length == 0) {
            return ""
        }
        else {
            return value.substring(0,1)
        }
    },
    /**
     * 数字最大值处理，超过10000显示9999+
     */
    valNumberMaxHandler : function(value, placeholder) {
        if (value == null || (value+"").length < 1 || isNaN(value)) {
            return placeholder ? placeholder:"0";
        }
        else if (value >= 10000) {
            return '9999+';
        }
        else return value;
    },
}
export default dfilters
