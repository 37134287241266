<template>
  <div class="cs-scroll">
    <div class="trade-p">
      <div class="btn-back" @click="toList">
        <img src="../assets/images/detail_ico_back.png" />
        <span>返回列表</span>
      </div>
      <div class="small-nav">专利详情</div>
    </div>
    <div class="trade-p">
      <table class="detail-table" cellpadding="0" cellspacing="0" border="0">
        <tbody>
          <tr>
            <td class="td-title">专利名称：</td>
            <td colspan="5">{{detailData.outhor_num}}_{{detailData.patent_name}}</td>
          </tr>
          <tr>
            <td class="td-title">专利类型：</td>
            <td>{{detailData.type_name}}</td>
            <td class="td-title">申请号：</td>
            <td>{{detailData.request_num}}</td>
            <td class="td-title">申请日期：</td>
            <td>{{detailData.request_date}}</td>
          </tr>
          <tr>
            <td class="td-title">授权公布号：</td>
            <td>{{detailData.outhor_num}}</td>
            <td class="td-title">授权公布日：</td>
            <td>{{detailData.outhor_date}}</td>
            <td class="td-title">代理人：</td>
            <td>{{detailData.agent_people}}</td>
          </tr>
          <tr>
            <td class="td-title">分类号：</td>
            <td colspan="5">{{detailData.category_num}}</td>
          </tr>
          <tr>
            <td class="td-title">申请人：</td>
            <td colspan="5">{{detailData.patent_person}}</td>
          </tr>
          <tr>
            <td class="td-title">发明人：</td>
            <td colspan="5">{{detailData.designer}}</td>
          </tr>
          <tr>
            <td class="td-title">专利代理机构：</td>
            <td colspan="5">{{detailData.agent}}</td>
          </tr>
          <tr>
            <td class="td-title">详情信息：</td>
            <td colspan="5">{{detailData.brief}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import listMixin from "../mixin/listMixin";
  import ServerInterface from "../js/common/ServerInterface";

  export default {
    name: "tradeMarkDetail",
    mixins: [listMixin],
    data(){
      return{
        detailData:{},
      }
    },
    mounted() {
      this.getDetail()
    },
    methods:{
      toList(){
        this.$router.push({
          path: `/patentList`,
          query:{
            creditNo: this.$route.query.creditNo,
            pageNumber: this.$route.query.pageNumber,
          }
        })
      },
      getDetail() {
        let form = this.getBaseForm()
        form.creditNo = this.$route.query.creditNo
        form.applicationNumber = this.$route.query.patentRequestNum
        ServerInterface.post("PatentDetail.html", form, (obj) => {
          this.detailData = JSON.parse(obj.data)
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/detail";
</style>