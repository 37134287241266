import ServerInterface from "./ServerInterface";
import Cookies from 'js-cookie'

export default {
  replaceAll,
  getBaseForm,
  cloneObj,
  uploadFile,
  getYesNoList,
  getCookieObj,
  getCookieString,
  setCookies,
}

function replaceAll(originString, replaceString, toString) {
  return originString.replace(new RegExp(replaceString, "gm"), toString)
}

function getBaseForm() {
  const form = {
    interfaceVersion: 20210105,
    orderBy: 'tableId desc',
  }
  let admin = getCookieObj("loginAdmin")
  if (admin) {
    //console.log(admin)
    form.loginAdminToken = admin.loginAdminToken
  }

  // var admin = getCookieObj("admin")
  // if (admin === undefined) {
  //   admin = JSON.parse(sessionStorage.getItem("admin"));
  // }
  // if (admin != null) {
  //   form.loginAdminToken = admin.token
  // }

  // console.log(form)
  return form
}

function cloneObj(originObj) {
  const json = JSON.stringify(originObj)
  const newObj = JSON.parse(json)
  return newObj
}

//上传文件
function uploadFile(event, extra, onUploadSuccess) {
  //上传文件
  let formData = new FormData();
  formData.append("uploadFile", event.target.files[0]);
  formData.append('interfaceVersion', 20200710);
  const admin = getCookieObj("loginAdmin")
  if (admin !== undefined) {
    formData.append('loginAdminToken', admin.loginAdminToken)
  }
  formData.append('extra', JSON.stringify(extra));
  ServerInterface.defaultPost('/saSingleFileUpload.sahtml', formData, jsonObj => {
    console.info(jsonObj);
    //GlobalData.API_SERVICE_SERVER_BASE_URL+jsonObj.filePath;
    event.target.value = "";

    onUploadSuccess(jsonObj)
    // importInfo(this_, jsonObj.filePath);
  }, jsonObj => {
    //this.$message.error(jsonObj.info);
    console.info(jsonObj);
    event.target.value = "";
  }, function () {
    event.target.value = "";
  })
}

function getYesNoList() {
  return [
    {
      label: 'Yes',
      value: 1
    }, {
      label: 'No',
      value: 2
    }
  ]

}

function getCookieObj(param) {
  const cookieString = Cookies.get(param)
  var cookieObj = undefined
  if (cookieString !== undefined && cookieString !== "undefined" && cookieString != null && cookieString !== 'null' && cookieString.length > 0) {
    cookieObj = JSON.parse(cookieString)
  }
  return cookieObj
}

function getCookieString(param) {
  const cookieString = Cookies.get(param)
  if (cookieString !== undefined && cookieString !== "undefined" && cookieString != null && cookieString !== 'null' && cookieString.length > 0) {
    return cookieString
  } else {
    return undefined
  }

}

function setCookies(param, obj, expires) {
  //失效时间，天
  if (expires == null) expires = 30;
  Cookies.set(param, obj, {
    expires: expires
  })
}
