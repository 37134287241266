<template>
  <div>
    <div class="cs-scroll">
      <div class="search-p" style="padding-top: 0px">
        <div class="btn-back" @click="toHome">
          <img src="../assets/images/detail_ico_back.png" />
          <span>返回首页</span>
        </div>
        <div class="small-nav" v-if="this.$route.query.type == 1">
          作品著作权企业共 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条
        </div>
        <div class="small-nav" v-if="this.$route.query.type == 2">
          软件著作权企业共 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条
        </div>
      </div>
      <div class="search-p">
        <div class="search-list">
          <div class="search-item" v-for="(item,index) in companyList" @click="goToOverviewPage(item)">
            <div class="title" ref="companyLetter">{{item.companyName | firstLetterHandler}}</div>
            <div class="company-info">
              <h1>{{item.companyName}}</h1>
              <div class="text-item">
                <div>作品名称：{{item.productionName}}</div>
                <div style="min-height: 23px"><span v-if="theType == 1">类别：{{item.typeName}}</span></div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-page">
      <el-pagination
          @size-change="handleSizeChange"
          background
          @current-change="handleChangePage"
          :current-page="pagination.pageNumber"
          :page-size="pagination.countPerPage"
          layout="prev,pager,next,total,jumper"
          :total="pagination.totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "company",
    mixins: [listMixin],
    data(){
      return{
        theType: null,
        colorList:['#01bff5','#e4c14b','#0653be'],

        companyList:[],//企业列表
      }
    },
    watch: {
      $route: { // 亲测有效,我是用来监听query参数变化
        handler() {
          this.theType = this.$route.query.type
          this.pagination.pageNumber = 1
          //深度监听，同时也可监听到param参数变化
          this.getList()
        },
        deep: true,
      }
    },
    mounted() {
      this.theType = this.$route.query.type
      if (this.theType == null) {
        this.theType = 1
      }
      this.getList()
    },
    methods:{
      handleSizeChange(val) {
        this.pagination.countPerPage = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pagination.pageNumber = val
        this.getList()
      },
      //企业列表-著作权企业列表
      getList() {
        let url = ""
        if (this.theType == 1) {
          //企业列表-著作权企业列表
          url = "CopyrightEnterpriseForPageList.html"
        } else if (this.theType == 2) {
          //企业列表-软件著作权企业列表
          url = "CopyrightSoftEnterpriseForPageList.html"
        }

        let form = this.getPaginationForm();
        ServerInterface.post(url, form, (obj) => {
          this.refreshPagination(obj)
          this.companyList = JSON.parse(obj.enterpriseList);

          //公司名称首字母背景色
          this.$nextTick(()=>{
            let num = 0;
            for( let i = 0;i < this.$refs.companyLetter.length;i++)
            {
              if(num==3)
              {num=0}
              this.$refs.companyLetter[i].style.background=this.colorList[num];
              num++;
            }
          })
        })
      },
      //跳转到概览页面
      goToOverviewPage(obj) {
        //初次加载时，通过路由传值加载企业概览信息
        this.$router.push({
          path: `/`,
          query:{
            creditNo: obj.creditNo
          }
        })
      },
      toHome(){
        this.$router.push({
          path: `/`,
          query:{
            creditNo: this.$route.query.creditNo,
          }
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/list";
</style>
