<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">股权出质</div>
        <div class="trade-num">找到 <span>{{pagination.totalCount | valNumberMaxHandler}}</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <el-table :data="qualityList" border stripe style="width: 100%" height="calc(100vh - 380px)">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pagination.pageNumber - 1) * pagination.countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="登记日期" width="130" align="center"></el-table-column>
        <el-table-column prop="number" label="登记编号" min-width="210"></el-table-column>
        <el-table-column prop="pledgor" label="出质人" width="160"></el-table-column>
        <el-table-column prop="pawnee" label="质权人" min-width="200" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" width="120" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status == '有效'" class="btn-td green">{{scope.row.status}}</div>
            <div v-else class="btn-td warning">{{scope.row.status}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="110" align="center">
          <template slot-scope="scope">
            <div @click="toDetail(scope.row)" style="cursor:pointer;">查看详情</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
          background
          @current-change="handleChangePage"
          :current-page="pagination.pageNumber"
          :page-size="pagination.countPerPage"
          layout="prev,pager,next,total,jumper"
          :total="pagination.totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import ServerInterface from "../js/common/ServerInterface";
  import baseMinxin from "../mixin/baseMixin";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "qualitiesList",
    mixins: [baseMinxin,listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        qualityList: []
      }
    },
    mounted() {
      if (this.$route.query.pageNumber != null && this.$route.query.pageNumber != 'undefined' && this.$route.query.pageNumber != '') {
        this.pagination.pageNumber = Number(this.$route.query.pageNumber)
      }
      this.getList();
    },
    methods:{
      toDetail(item){
        this.$router.push({
          path: `/qualitiesDetail`,
          query:{
            creditNo: this.$route.query.creditNo,
            date: item.date,
            number: item.number,
            pledgor: item.pledgor,
            pageNumber: this.pagination.pageNumber
          }
        })
      },
      getList() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        ServerInterface.post("GetEquityQualitiesByName.html", form, (obj) => {
          this.refreshPagination(obj);
          let result = JSON.parse(obj.data);
          this.qualityList = result.items;
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
</style>
