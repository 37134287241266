<template>
  <div>
    <head-company></head-company>
    <div class="trade-p">
      <div class="trade-total">
        <div class="trade-title">工商照面</div>
        <div class="trade-num">共 <span>1</span> 条相关信息</div>
      </div>
    </div>
    <div class="trade-p">
      <table class="detail-table" cellpadding="0" cellspacing="0" border="0">
        <tbody>
          <tr>
            <td class="td-title">法定代表人：</td>
            <td>{{baseInfoObj.operName}}</td>
            <td class="td-title">四级行业：</td>
            <td>{{baseInfoObj.domainName}}</td>
            <td class="td-title">企业大类：</td>
            <td v-if="baseInfoObj.type_new == '01'">大陆企业</td>
            <td v-if="baseInfoObj.type_new == '02'">社会组织</td>
            <td v-if="baseInfoObj.type_new == '03'">机关及事业单位</td>
            <td v-if="baseInfoObj.type_new == '04'">港澳台及国外企业</td>
            <td v-if="baseInfoObj.type_new == '05'">律所及其他组织机构</td>
            <td v-if="baseInfoObj.type_new == null || baseInfoObj.type_new == ''">其它</td>
          </tr>
          <tr>
            <td class="td-title">注册资本：</td>
            <td>{{baseInfoObj.registCapi}}</td>
            <td class="td-title">实缴资本：</td>
            <td>{{baseInfoObj.actualCapi}}</td>
            <td class="td-title">企业注册号：</td>
            <td>{{baseInfoObj.regNo}}</td>
          </tr>
          <tr>
            <td class="td-title">营业开始日期：</td>
            <td>{{baseInfoObj.termStart}}</td>
            <td class="td-title">营业结束日期：</td>
            <td>{{baseInfoObj.termEnd}}</td>
            <td class="td-title">核准日期：</td>
            <td>{{baseInfoObj.checkDate}}</td>
          </tr>
          <tr>
            <td class="td-title">成立日期：</td>
            <td>{{baseInfoObj.startDate}}</td>
            <td class="td-title">注销日期：</td>
            <td>{{baseInfoObj.endDate}}</td>
            <td class="td-title">所属工商局：</td>
            <td>{{baseInfoObj.belongOrg}}</td>
          </tr>
          <tr>
            <td class="td-title">历史名称：</td>
            <td colspan="5">{{baseInfoObj.historyNames}}</td>
          </tr>
          <tr>
            <td class="td-title">经营状态：</td>
            <td colspan="5">{{baseInfoObj.status}}</td>
          </tr>
          <tr>
            <td class="td-title">组织机构号：</td>
            <td colspan="2">{{baseInfoObj.orgNo}}</td>
            <td class="td-title">统一社会信用代码：</td>
            <td colspan="2">{{baseInfoObj.creditNo}}</td>
          </tr>
          <tr>
            <td class="td-title">地址：</td>
            <td colspan="5">{{baseInfoObj.address}}</td>
          </tr>
          <tr>
            <td class="td-title">经营范围：</td>
            <td colspan="5">{{baseInfoObj.scope}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import headCompany from "./headCompany";
  import ServerInterface from "../js/common/ServerInterface";
  import listMixin from "../mixin/listMixin";

  export default {
    name: "basicInfo",
    mixins: [listMixin],
    components:{
      headCompany
    },
    data(){
      return{
        baseInfoObj:{}
      }
    },
    mounted() {
      this.getBasicInfo();
    },
    methods:{
      getBasicInfo() {
        let form = this.getPaginationForm();
        form.creditNo = this.$route.query.creditNo;
        ServerInterface.post("GetBasicInfo.html", form, (obj) => {
          this.baseInfoObj = JSON.parse(obj.data);
        })
      },
    }
  }
</script>

<style scoped lang="less">
  @import "src/assets/css/otherList";
  @import "src/assets/css/detail";
</style>
